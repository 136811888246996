import React, { useState } from 'react';
import './headerCustom.css'

const Tooltip = ({ children, content, idTool }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {setIsVisible(true)  };
  const handleMouseLeave = () => {setIsVisible(false)};

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      { isVisible&& <div data-element-type={idTool} className="tooltip">{content}</div>}
    </div>
  );
};

export default Tooltip;