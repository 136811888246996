import {
  RESET_COMPONENT,
  TOGGLE_HUB_CONNECTION,
  TOGGLE_CHECK_SCRIPT,
  //
  RESET_LIST_DATASEARCH,
  RESET_LIST_SELECTED,
  RESET_SEARCH_INPUT,
  RESET_SELECTED_JOB,
  RESET_VIEW_DELETE,
  RESET_DISPLAYED_DATA,
  ADD_LIST_USERS,
  ADD_CHILDREN_JOB_DETAIL,
  ADD_JOB_DETAIL,
  ADD_SELECTED,
  ADD_LIST_JOBS_SELECTED,
  ADD_LIST_SELECTEDS,
  ADD_LIST_WORKFLOWS,
  ADD_LIST_DATASEARCH,
  REMOVE_SELECTED,
  REMOVE_LIST_SELECTEDS,
  REMOVE_ALL_LIST_SELECTEDS,
  TOGGLE_USER_LOGIN,
  TOGGLE_TYPE,
  TOGGLE_WORKFLOW,
  TOGGLE_STATE,
  TOGGLE_LIST_TYPES,
  TOGGLE_LIST_WORKFLOWS,
  TOGGLE_LIST_STATES,
  TOGGLE_LIST_CUSTOM_DISPLAYED,
  TOGGLE_LIST_WORKFLOW_COMMENT,
  TOGGLE_LOGIN_IN,
  TOGGLE_UPDATE_USERS,
  TOGGLE_UPDATE_USER_ASSIGN,
  TOGGLE_MODAL_SEARCH_CLOSE,
  TOGGLE_VALUE_MONITORING,
  TOGGLE_WORKFLOW_SELECTED,
  TOGGLE_REFRESH_IPS,
  TOGGLE_HEIGHT_MODAL,
  TOGGLE_VIEW_DELETE,
  TOGGLE_LOAD_DATA,
  TOGGLE_IS_ATTRIBUTES,
} from './constants';
const initState = {
  // string
  hubConnection: '',
  currentPath: '',
  // boolean
  isCheckScript: false,
  //
  //
  valueSearchMonitoring: '',
  //Object Data

  userLogin: {},
  jobsSelected: [],
  workflowSelected: [],
  listWorkflowComment: [],
  listUsers: [],
  childrenjobDetail: [],
  jobDetail: [],
  listTypes: [
    { name: 'Batch', value: 'BATCH', group: 'Type', isCheck: false },
    { name: 'On Demand', value: 'ONLINE', group: 'Type', isCheck: false },
    { name: 'System Processes', value: 'SYSTEM', group: 'Type', isCheck: false },
  ],
  listWorkflows: [],
  listStatus: [
    { name: 'Canceled', value: 'Canceled', group: 'Status', isCheck: false },
    { name: 'Canceling', value: 'Canceling', group: 'Status', isCheck: false },
    { name: 'Completed', value: 3, group: 'Status', isCheck: false },
    { name: 'Failed', value: 4, group: 'Status', isCheck: false },
    { name: 'Failing', value: 'Failing', group: 'Status', isCheck: false },
    { name: 'Held', value: 'Held', group: 'Status', isCheck: false },
    { name: 'Processing', value: 'Processing', group: 'Status', isCheck: false },
  ],
  listSelectedItems: [],
  listDataSearch: [],
  listCustomDisplayed: [
    { Name: 'Custom Job ID', isCheck: true, type: 'All' },
    { Name: 'Assign User', isCheck: true, type: 'All' },
    { Name: 'Status', NameIPS: 'IPS Status', isCheck: true, type: 'All' },
    { Name: 'Node', isCheck: true, type: 'IPS Monitoring' },
    { Name: 'IPS Port', isCheck: true, type: 'IPS Monitoring' },
    { Name: 'Cluster', isCheck: true, type: 'Job Monitoring' },
    { Name: 'Duration', NameIPS: 'Processing Time on IPS', isCheck: true, type: 'All' },
    { Name: 'Job Priority', NameIPS: 'Priority', isCheck: true, type: 'IPS Monitoring' },
    { Name: 'Order', isCheck: true, type: 'IPS Monitoring' },
    { Name: 'Started', isCheck: true, type: 'All' },
  ],
  // boolean
  isLoadingWorkflow: false,
  isLoggedIn: false,
  isModalSearchClose: false,
  isUpdateUsers: false,
  isUpdateUserAssign: false,
  isRefreshIps: true,
  isViewDelete: false,
  isLoadData: false,
  isAttributes: false,
  //int
  heightModal: null,
};

function reducer(state, action) {
  switch (action.type) {
    //reset
    case RESET_COMPONENT:
      return {
        ...state,
        listDataSearch: initState.listDataSearch,
        listSelectedItems: initState.listSelectedItems,
        jobsSelected: initState.jobsSelected,
        isViewDelete: initState.isViewDelete,
        listCustomDisplayed: initState.listCustomDisplayed,
        valueSearchMonitoring: initState.valueSearchMonitoring,
      };
    //toggle string
    case TOGGLE_HUB_CONNECTION:
      return {
        ...state,
        hubConnection: action.payload,
      };
    //toggle boolean
    case TOGGLE_CHECK_SCRIPT:
      return {
        ...state,
        isCheckScript: action.payload,
      };
    //
    //
    //reset
    case RESET_LIST_DATASEARCH:
      return {
        ...state,
        listDataSearch: initState.listDataSearch,
      };
    case RESET_LIST_SELECTED:
      return {
        ...state,
        listSelectedItems: initState.listSelectedItems,
      };
    case RESET_SELECTED_JOB:
      return {
        ...state,
        jobsSelected: initState.jobsSelected,
      };
    case RESET_VIEW_DELETE:
      return {
        ...state,
        isViewDelete: initState.isViewDelete,
      };
    case RESET_DISPLAYED_DATA:
      return {
        ...state,
        listCustomDisplayed: initState.listCustomDisplayed,
      };
    case RESET_SEARCH_INPUT:
      return {
        ...state,
        valueSearchMonitoring: initState.valueSearchMonitoring,
      };
    //Add Custom
    // Add
    case ADD_LIST_JOBS_SELECTED:
      return {
        ...state,
        jobsSelected: [...action.payload],
      };
    case ADD_LIST_USERS:
      return {
        ...state,
        listUsers: action.payload,
      };
    case ADD_CHILDREN_JOB_DETAIL:
      return {
        ...state,
        childrenjobDetail: action.payload,
      };
    case ADD_JOB_DETAIL:
      return {
        ...state,
        jobDetail: action.payload,
      };
    case ADD_LIST_WORKFLOWS:
      return {
        ...state,
        listWorkflows: [...action.payload],
      };
    case ADD_LIST_DATASEARCH:
      const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

      return {
        ...state,
        listDataSearch: [...payloadArray],
      };
    case ADD_SELECTED:
      const group = action.payload.group;
      const groupItems = state.listSelectedItems.filter((selectedItem) => selectedItem.group === group);
      const selectedUnGroup = state.listSelectedItems.filter(
        (item) => !groupItems.some((listItem) => listItem.value === item.value),
      );
      const updatedSelectedItems = [...selectedUnGroup, ...groupItems];
      return {
        ...state,
        listSelectedItems: [...updatedSelectedItems, action.payload],
      };

    case ADD_LIST_SELECTEDS:
      const groupListItems = [...action.payload];
      const updatedGroupListItems = groupListItems.map((item) => ({ ...item, isCheck: true }));
      const listSelectedUnGroup = state.listSelectedItems.filter(
        (item) => !groupListItems.some((listItem) => listItem.value === item.value),
      );
      return {
        ...state,
        listSelectedItems: [...listSelectedUnGroup, ...updatedGroupListItems],
      };

    //remove
    case REMOVE_SELECTED:
      const groupIndex = action.payload.group;
      const groupIndexItems = state.listSelectedItems.filter((selectedItem) => selectedItem.group === groupIndex);
      const newgroupItems = groupIndexItems.filter((selectedItem) => selectedItem.value !== action.payload.value);
      const listSelectedIndexGroup = state.listSelectedItems.filter(
        (item) => !groupIndexItems.some((listItem) => listItem.value === item.value),
      );
      return {
        ...state,
        listSelectedItems: [...listSelectedIndexGroup, ...newgroupItems],
      };
    case REMOVE_LIST_SELECTEDS:
      const groupListItemsRemove = [...action.payload];
      const listSelectedGroup = state.listSelectedItems.filter(
        (item) => !groupListItemsRemove.some((listItem) => listItem.value === item.value),
      );
      return {
        ...state,
        listSelectedItems: [...listSelectedGroup],
      };
    case REMOVE_ALL_LIST_SELECTEDS:
      return {
        ...state,
        listSelectedItems: [],
      };
    //toggle list
    case TOGGLE_USER_LOGIN:
      return {
        ...state,
        userLogin: action.payload,
      };
    case TOGGLE_TYPE:
      const updatedType = state.listTypes.map((item) =>
        item.value === action.payload.value ? { ...item, isCheck: !item.isCheck } : item,
      );
      return {
        ...state,
        listTypes: updatedType,
      };
    case TOGGLE_WORKFLOW:
      const updatedWorkflow = state.listWorkflows.map((item) =>
        item.value === action.payload.value ? { ...item, isCheck: !item.isCheck } : item,
      );
      return {
        ...state,
        listWorkflows: updatedWorkflow,
      };
    case TOGGLE_STATE:
      const updatedState = state.listStatus.map((item) =>
        item.value === action.payload.value ? { ...item, isCheck: !item.isCheck } : item,
      );
      return {
        ...state,
        listStatus: updatedState,
      };
    case TOGGLE_LIST_TYPES:
      const updatedListTypes = state.listTypes.map((item) => ({ ...item, isCheck: !action.payload }));
      return {
        ...state,
        listTypes: updatedListTypes,
      };

    case TOGGLE_LIST_WORKFLOWS:
      const updatedListWorkflows = state.listWorkflows.map((item) => ({ ...item, isCheck: !action.payload }));
      return {
        ...state,
        listWorkflows: updatedListWorkflows,
      };

    case TOGGLE_LIST_STATES:
      const updatedListStates = state.listStatus.map((item) => ({ ...item, isCheck: !action.payload }));
      return {
        ...state,
        listStatus: updatedListStates,
      };
    case TOGGLE_LIST_CUSTOM_DISPLAYED:
      return {
        ...state,
        listCustomDisplayed: action.payload,
      };
    case TOGGLE_LIST_WORKFLOW_COMMENT:
      return {
        ...state,
        listWorkflowComment: action.payload,
      };
    //toggle boolean
    case TOGGLE_LOGIN_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case TOGGLE_MODAL_SEARCH_CLOSE:
      return {
        ...state,
        isModalSearchClose: action.payload,
      };
    case TOGGLE_UPDATE_USERS:
      return {
        ...state,
        isUpdateUsers: action.payload,
      };
    case TOGGLE_UPDATE_USER_ASSIGN:
      return {
        ...state,
        isUpdateUserAssign: action.payload,
      };
    case TOGGLE_REFRESH_IPS:
      return {
        ...state,
        isRefreshIps: action.payload,
      };
    case TOGGLE_VIEW_DELETE:
      return {
        ...state,
        isViewDelete: action.payload,
      };
    case TOGGLE_LOAD_DATA:
      return {
        ...state,
        isLoadData: action.payload,
      };
    case TOGGLE_IS_ATTRIBUTES:
      return {
        ...state,
        isAttributes: action.payload,
      };

    case TOGGLE_VALUE_MONITORING:
      return {
        ...state,
        valueSearchMonitoring: action.payload,
      };
    case TOGGLE_WORKFLOW_SELECTED:
      return {
        ...state,
        workflowSelected: action.payload,
      };

    //toggle int
    case TOGGLE_HEIGHT_MODAL:
      return {
        ...state,
        heightModal: action.payload,
      };
    default:
      throw new Error('Invalid action.');
  }
}

export { initState };
export default reducer;
