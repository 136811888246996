const OpenModal = ({ children, idModal, title = 'Open Modal', colorText, type, classStyle, onHandle }) => {
  return (
    <button
      type="button"
      style={{ color: colorText }}
      className={`${
        type === 'Dashboard'
          ? `btn-round btn ${classStyle}`
          : `btn btn-default btn-success btn-outline-default waves-effect md-trigger ${colorText}`
      } `}
      name={idModal}
      data-bs-toggle="modal"
      data-bs-target={`#${idModal}`}
      title={title}
      onClick={onHandle}
    >
      {children}
    </button>
  );
};

export default OpenModal;
