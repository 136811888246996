//reset
export const RESET_COMPONENT = 'reset_component';
//toggle string
export const TOGGLE_HUB_CONNECTION = 'toggle_hub_connection';
//toggle boolean
export const TOGGLE_CHECK_SCRIPT = 'toggle_check_script';
//
///
//reset
export const RESET_LIST_DATASEARCH = 'reset_list_datasearch';
export const RESET_LIST_SELECTED = 'reset_list_selecter';
export const RESET_SELECTED_JOB = 'reset_selecter_job';
export const RESET_VIEW_DELETE = 'reset_view_delete';
export const RESET_DISPLAYED_DATA = 'reset_displayed_data';
export const RESET_SEARCH_INPUT = 'reset_search_input';

//add custom
//add list
export const ADD_LIST_JOBS_SELECTED = 'add_list_jobs_selected';
export const ADD_LIST_USERS = 'add_list_users';
export const ADD_CHILDREN_JOB_DETAIL = 'add_children_job_detail';
export const ADD_JOB_DETAIL = 'add_job_detail';
export const ADD_SELECTED = 'add_select';
export const ADD_LIST_SELECTEDS = 'add_list_selects';
export const ADD_LIST_WORKFLOWS = 'add_list_workflows';
export const ADD_LIST_DATASEARCH = 'add_list_datasearch';
//remove list
export const REMOVE_SELECTED = 'remove_select';
export const REMOVE_LIST_SELECTEDS = 'remove_list_selects';
export const REMOVE_ALL_LIST_SELECTEDS = 'remove_all_list_selects';
//toggle list
export const TOGGLE_USER_LOGIN = 'toggle_user_login';
export const TOGGLE_TYPE = 'toggle_types';
export const TOGGLE_WORKFLOW = 'toggle_workflow';
export const TOGGLE_STATE = 'toggle_state';
export const TOGGLE_LIST_TYPES = 'toggle_list_types';
export const TOGGLE_LIST_WORKFLOWS = 'toggle_list_workflows';
export const TOGGLE_LIST_STATES = 'toggle_list_states';
export const TOGGLE_LIST_CUSTOM_DISPLAYED = 'toggle_list_custom_displayed';
export const TOGGLE_LIST_WORKFLOW_COMMENT = 'toggle_list_workflow_comment';
//toggle boolean
export const TOGGLE_LOGIN_IN = 'toggle_login_in';
export const TOGGLE_MODAL_SEARCH_CLOSE = 'toggle_modal_search_close';
export const TOGGLE_UPDATE_USERS = 'toggle_update_users';
export const TOGGLE_UPDATE_USER_ASSIGN = 'toggle_update_user_assign';
export const TOGGLE_REFRESH_IPS = 'toggle_refresh_ips';
export const TOGGLE_VIEW_DELETE = 'toggle_view_delete';
export const TOGGLE_LOAD_DATA = 'toggle_load_data';
export const TOGGLE_IS_ATTRIBUTES = 'toggle_is_attributes';

export const TOGGLE_VALUE_MONITORING = 'toggle_value_monitoring';
export const TOGGLE_WORKFLOW_SELECTED = 'toggle_workflow_selected';
//toggle int
export const TOGGLE_HEIGHT_MODAL = 'toggle_height_modal';
