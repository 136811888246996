import { useQuery } from '@tanstack/react-query';
import { fetchAPIGet, fetchAPIGet2 } from '../store/handles';
import { useRef } from 'react';
//Companies
export const useCompanies = (isEnabled = true) => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: async () => {
      return fetchAPIGet('Company/Companies');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

//
export const useApplicationsLicenses = (isEnabled = true) => {
  return useQuery({
    queryKey: ['applicationsLicenses'],
    queryFn: async () => {
      return fetchAPIGet('ApplicationsLicenses');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

export const useUsers = (isEnabled = true) => {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      return fetchAPIGet('User/Users');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

export const useQuantityStatistics = (isEnabled = true) => {
  return useQuery({
    queryKey: ['quantityStatistics'],
    queryFn: async () => {
      return fetchAPIGet('User/QuantityStatistics');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

export const useUserByName = (name, isEnabled = true) => {
  return useQuery({
    queryKey: ['userByName'],
    queryFn: async () => {
      return fetchAPIGet(`User?name=${name}`);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

//Configuration
export const useConfiguration = (username, nameConfig, isEnabled = true) => {
  return useQuery({
    queryKey: [nameConfig],
    queryFn: async () => {
      return fetchAPIGet2('ReScaleConfig', { username, nameConfig });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

//job
export const useDashboardJobsNumber = (userName, isEnabled = true) => {
  const previousData = useRef(null);

  return useQuery({
    queryKey: ['dashboardJobsNumber'],
    queryFn: async () => {
      const data = await fetchAPIGet(`DashboardReScale/DashboardNumber?userName=${userName}`);
      return data;
    },
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
    select: (newData) => {
      if (JSON.stringify(newData) !== JSON.stringify(previousData.current)) {
        previousData.current = newData;
        return newData;
      }
      return previousData.current;
    },
  });
};