import classNames from 'classnames/bind';
import styles from './HeaderContent.module.scss';
const cx = classNames.bind(styles);
function HeaderContent({ title, icon, breadcrumb, link, typeIcon = null }) {
  return (
    <div className="page-header card">
      <div className="row align-items-end">
        <div className="col-lg-8">
          <div className="page-header-title">
            {typeIcon === 'fa' ? (
              <i className={cx('bg-c-blue', icon)}></i>
            ) : (
              <i className={cx('feather', 'icofont', 'bg-c-blue', icon)}></i>
            )}

            <div className="d-inline">
              <h5>{title}</h5>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="page-header-breadcrumb">
            <ul className=" breadcrumb breadcrumb-title breadcrumb-padding"></ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderContent;
