import { ContainerModal } from '../../component';
const ModalDelete = ({ idModal, title = 'Title Modal', textDelete, handleDelete }) => {
  return (
    <ContainerModal
      idModal={idModal}
      title={title}
      listButton={[{ name: 'CANCEL' }, { name: 'DELETE', isHandle: handleDelete, colorButton: 'btn-danger' }]}
    >
      <p>{textDelete}</p>
    </ContainerModal>
  );
};

export default ModalDelete;
