import {
  RESET_COMPONENT,
  TOGGLE_HUB_CONNECTION,
  TOGGLE_CHECK_SCRIPT,
  //
  //
  RESET_LIST_DATASEARCH,
  RESET_SEARCH_INPUT,
  RESET_LIST_SELECTED,
  RESET_SELECTED_JOB,
  RESET_VIEW_DELETE,
  RESET_DISPLAYED_DATA,
  ADD_LIST_USERS,
  ADD_CHILDREN_JOB_DETAIL,
  ADD_JOB_DETAIL,
  ADD_SELECTED,
  ADD_LIST_JOBS_SELECTED,
  ADD_LIST_SELECTEDS,
  ADD_LIST_WORKFLOWS,
  ADD_LIST_DATASEARCH,
  REMOVE_SELECTED,
  REMOVE_LIST_SELECTEDS,
  REMOVE_ALL_LIST_SELECTEDS,
  TOGGLE_USER_LOGIN,
  TOGGLE_TYPE,
  TOGGLE_WORKFLOW,
  TOGGLE_STATE,
  TOGGLE_LIST_TYPES,
  TOGGLE_LIST_WORKFLOWS,
  TOGGLE_LIST_STATES,
  TOGGLE_LIST_CUSTOM_DISPLAYED,
  TOGGLE_LIST_WORKFLOW_COMMENT,
  TOGGLE_LOGIN_IN,
  TOGGLE_UPDATE_USERS,
  TOGGLE_UPDATE_USER_ASSIGN,
  TOGGLE_MODAL_SEARCH_CLOSE,
  TOGGLE_VALUE_MONITORING,
  TOGGLE_WORKFLOW_SELECTED,
  TOGGLE_REFRESH_IPS,
  TOGGLE_HEIGHT_MODAL,
  TOGGLE_VIEW_DELETE,
  TOGGLE_LOAD_DATA,
  TOGGLE_IS_ATTRIBUTES,
} from './constants';

//reset
export const resetComponent = () => ({
  type: RESET_COMPONENT,
});
//toggle string
export const toggleHubConnection = (payload) => ({
  type: TOGGLE_HUB_CONNECTION,
  payload,
});
//toggle boolean
export const toggleCheckScript = (payload) => ({
  type: TOGGLE_CHECK_SCRIPT,
  payload,
});
//

//
//reset
export const resetListDataSearch = (payload) => ({
  type: RESET_LIST_DATASEARCH,
});
export const resetListSelecter = (payload) => ({
  type: RESET_LIST_SELECTED,
});
export const resetSelecterJob = (payload) => ({
  type: RESET_SELECTED_JOB,
});
export const resetViewDelete = (payload) => ({
  type: RESET_VIEW_DELETE,
});
export const resetDisplayedData = (payload) => ({
  type: RESET_DISPLAYED_DATA,
});
export const resetSearchInput = (payload) => ({
  type: RESET_SEARCH_INPUT,
});
//add custom
//add list
export const addListJobsSelected = (payload) => ({
  type: ADD_LIST_JOBS_SELECTED,
  payload,
});
export const addListUsers = (payload) => ({
  type: ADD_LIST_USERS,
  payload,
});
export const addChildrenJobDetail = (payload) => ({
  type: ADD_CHILDREN_JOB_DETAIL,
  payload,
});
export const addJobDetail = (payload) => ({
  type: ADD_JOB_DETAIL,
  payload,
});
export const addSelect = (payload) => ({
  type: ADD_SELECTED,
  payload: { ...payload, isCheck: true },
});
export const addListSelecteds = (payload) => ({
  type: ADD_LIST_SELECTEDS,
  payload,
});
export const addListWorkflows = (payload) => ({
  type: ADD_LIST_WORKFLOWS,
  payload,
});
export const addListDataSearch = (payload) => ({
  type: ADD_LIST_DATASEARCH,
  payload,
});
//remove
export const removeSelect = (payload) => ({
  type: REMOVE_SELECTED,
  payload,
});

export const removeListSelect = (payload) => ({
  type: REMOVE_LIST_SELECTEDS,
  payload,
});
export const removeAllListSelect = () => ({
  type: REMOVE_ALL_LIST_SELECTEDS,
});
//toggle list
export const toggleUserLogin = (payload) => ({
  type: TOGGLE_USER_LOGIN,
  payload,
});
export const toggleTypes = (payload) => ({
  type: TOGGLE_TYPE,
  payload,
});

export const toggleWorkflows = (payload) => ({
  type: TOGGLE_WORKFLOW,
  payload,
});

export const toggleStates = (payload) => ({
  type: TOGGLE_STATE,
  payload,
});
export const toggleListTypes = (payload) => ({
  type: TOGGLE_LIST_TYPES,
  payload,
});

export const toggleListWorkflows = (payload) => ({
  type: TOGGLE_LIST_WORKFLOWS,
  payload,
});

export const toggleListStates = (payload) => ({
  type: TOGGLE_LIST_STATES,
  payload,
});
export const toggleListCustomDisplayed = (payload) => ({
  type: TOGGLE_LIST_CUSTOM_DISPLAYED,
  payload,
});
export const toggleListWorkflowComment = (payload) => ({
  type: TOGGLE_LIST_WORKFLOW_COMMENT,
  payload,
});
//toggle boolean
export const toggleLogin = (payload) => ({
  type: TOGGLE_LOGIN_IN,
  payload,
});
export const toggleModalSearchClose = (payload) => ({
  type: TOGGLE_MODAL_SEARCH_CLOSE,
  payload,
});
export const toggleUpdateUsers = (payload) => ({
  type: TOGGLE_UPDATE_USERS,
  payload,
});
export const toggleUpdateUserAssign = (payload) => ({
  type: TOGGLE_UPDATE_USER_ASSIGN,
  payload,
});
export const toggleRefreshIps = (payload) => ({
  type: TOGGLE_REFRESH_IPS,
  payload,
});
export const toggleViewDelete = (payload) => ({
  type: TOGGLE_VIEW_DELETE,
  payload,
});
export const toggleLoadData = (payload) => ({
  type: TOGGLE_LOAD_DATA,
  payload,
});
export const toggleIsAttributes = (payload) => ({
  type: TOGGLE_IS_ATTRIBUTES,
  payload,
});

export const toggleValueMonitoring = (payload) => ({
  type: TOGGLE_VALUE_MONITORING,
  payload,
});
export const toggleWorkflowSelected = (payload) => ({
  type: TOGGLE_WORKFLOW_SELECTED,
  payload,
});
//toggle int
export const toggleHeightModal = (payload) => ({
  type: TOGGLE_HEIGHT_MODAL,
  payload,
});
