import { Children, cloneElement } from 'react';
const TrackContainer = ({ children, dashboardConfig }) => {
  const childrenArray = Children.toArray(children);
  if (!dashboardConfig) {
    return <div> loading</div>;
  }

  const renderElement = () => {
    const chartTracks = childrenArray.filter(
      (c) =>
        c.type.displayName === 'ChartTrack' && dashboardConfig.some((u) => u.title === c.props.keyTracking && u.view),
    );
    const widgetTracks = childrenArray.filter(
      (c) =>
        c.type.displayName === 'WidgetTrack' && dashboardConfig.some((u) => u.title === c.props.keyTracking && u.view),
    );

    let renderArray = [];

    if (chartTracks.length === 1) {
      const widgetTrackGroups = [];
      for (let i = 0; i < widgetTracks.length; i += 3) {
        widgetTrackGroups.push(widgetTracks.slice(i, i + 3));
      }

      renderArray.push(
        <div className="row" key="chart-and-widget">
          {chartTracks.map((chartTrack, index) => (
            <div key={`chart-${index}`} className="col-md-12 col-xl-8">
              {cloneElement(chartTrack)}
            </div>
          ))}
          {widgetTrackGroups.map((group, groupIndex) => (
            <div key={`widget-group-${groupIndex}`} className="col-md-12 col-xl-4">
              {group.map((widgetTrack, index) => (
                <div key={`widget-${groupIndex}-${index}`}>{cloneElement(widgetTrack)}</div>
              ))}
            </div>
          ))}
        </div>,
      );
    } else if (chartTracks.length === 0 && widgetTracks.length > 0) {
      renderArray.push(
        <div className="row" key="only-widget">
          {widgetTracks.map((widgetTrack, index) => (
            <div key={`widget-${index}`} className="col-md-12 col-xl-4">
              {cloneElement(widgetTrack)}
            </div>
          ))}
        </div>,
      );
    }
    return renderArray;
  };

  return renderElement();
};

export default TrackContainer;
