import { useEffect } from 'react';

const Tablist = ({ listTab = [], dependency = null, parentId = null }) => {
  useEffect(() => {
    if (dependency) {
      activateFirstTab(parentId, listTab);
    }
    // eslint-disable-next-line
  }, [dependency, parentId]);

  const updateActiveTabPane = (parentId, linkTab) => {
    const parentElement = document.getElementById(parentId);
    if (!parentElement) return;

    parentElement.querySelectorAll('.tab-pane').forEach((pane) => {
      pane.classList.remove('active');
    });

    const activePane = parentElement.querySelector(`#${linkTab}`);
    if (activePane) {
      activePane.classList.add('active');
    }
  };

  const activateFirstTab = (parentId, listTab) => {
    const parentElement = document.getElementById(parentId);
    if (!parentElement) return;

    listTab.forEach((tab) => {
      const activePane = parentElement.querySelector(`a[href="#${tab.linkTab}"]`);
      if (activePane) {
        activePane.classList.remove('active');
      }
    });
    const firstTab = parentElement.querySelector(`a[href="#${listTab[0].linkTab}"]`);
    if (firstTab) {
      firstTab.classList.add('active');

      const tabInstance = new window.bootstrap.Tab(firstTab);
      tabInstance.show();

      updateActiveTabPane(parentId, listTab[0].linkTab);
    }
  };

  return (
    <ul className="nav nav-tabs tabs" role="tablist">
      {listTab
        .filter((item) => !item.isDisabled)
        .map((item, index) => (
          <li className="nav-item" key={index}>
            <a
              className={`nav-link ${index === 0 ? 'active' : ''}`}
              data-bs-toggle="tab"
              href={`#${item.linkTab}`}
              role="tab"
            >
              {item.name}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default Tablist;
