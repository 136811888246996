import { HubConnectionBuilder } from '@microsoft/signalr';
import { setSecureItem ,getSecureItem } from  "../component/SecureStorage/secureStorage";
let hubConnectionPnD;
export const createHubConnection = async () => {
  const hubHost = getSecureItem('hubHost');
  const hubTnTHost = getSecureItem('tntApiUrl');
  if (!hubHost) {
    console.error('HubHost is not available in sessionStorage.');
    return null;
  }
  hubConnectionPnD = new HubConnectionBuilder()
  .withUrl(`${hubTnTHost}trackandtraceDashboardhub`)
  .build();

  const hubConnection = new HubConnectionBuilder().withUrl(`${hubHost}notificationHub`).build();

  try {
    await hubConnection.start();
  } catch (e) {
    console.log('Error starting 1st hub connection:', e);
  }
  try {
    await hubConnectionPnD.start();
    console.log('Second hub connection started:', hubConnectionPnD);
  } catch (e) {
    console.log('Error starting second hub connection:', e);
  }
  return  hubConnection;

  return { hubConnection };
};

export { hubConnectionPnD };
