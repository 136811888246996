import { useEffect, useRef, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { handles } from '../../store';
import _ from 'lodash';
import { useQueryAPI } from '../../hooks';
import { Tablist, FormRow, ContainerModal } from '../../component';
import { RenderIf } from '../../utilities';
const ModalEditUser = ({
  idModal,
  title = 'Title Modal',
  selectUser,
  isInternalBasic,
  userByName,
  isReload,
  isUserExternalAdmin = false,
  numberUser,
  userUse
}) => {
  //store
  const { fetchAPIPut } = handles;
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      user: '',
      password: '',
      email: null,
      fullName: null,
      external: 0,
      adminPermission: false,
      managerAdmin: false,
      application: false,
    },
  });
  //watch
  const formValues = watch();

  //custom
  const customerInAppCustom = [
    {
      name: 'PND',
      checked: false,
      admin: false,
    },
    {
      name: 'reScale',
      checked: false,
      admin: false,
    },
    {
      name: 'reSort',
      checked: false,
      admin: false,
    },
    {
      name: 'TnT',
      checked: false,
      admin: false,
    },
  ];
  //hook
  const { useCompanies ,useApplicationsLicenses} = useQueryAPI;
  //Call API
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies();
  // const { useCompanies, useUserByName, useUsers, useApplicationsLicenses } = useQueryAPI;
  // data
  const [choiceCustomer, setChoiceCustomer] = useState('select customer');
  // useState
  const [customerInApp, setCustomerInApp] = useState(customerInAppCustom);
  const [customerInCustomer, setCustomerInCustomer] = useState([]);
  const [isShowRetention, setIsShowRetention] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isShowCustomer, setIsShowCustomer] = useState(true);
  const [isExternalAdmin, setIsExternalAdmin] = useState(false);
  const [userLogin, setUserLogin] = useState('');
  const isFirst = useRef(false);
  const isFirstExternal = useRef(false);
  const { data: applications, isLoading: isLoadingApplications } = useApplicationsLicenses();
  ///
  useEffect(() => {
    if (selectUser) {
      isFirstExternal.current = false;
      isFirst.current = false;
      const data = { ...selectUser };
      data.password = atob(data.password);

      data.adminPermission = JSON.parse(data.permission).includes('admin')
        ? `adminPermission${data.external ? 'External' : 'Internal'}`
        : null;
      data.application = JSON.parse(data.appAccess).length > 0;
      const listUserGroup = JSON.parse(data.userGroup);
      data.managerAdmin = listUserGroup.length > 0;
      const appAccessIndex = JSON.parse(data.appAccess);
      const appRoleIndex = JSON.parse(data.appRole);
      let allowedApps = {};
      if (data.external) {
        const choiceCustomer = companies.find((d) => d.id === data.companyId);
        setChoiceCustomer(choiceCustomer?.companyName);
        if (choiceCustomer) {
          allowedApps = JSON.parse(choiceCustomer?.allowedApps);
        } else {
          setChoiceCustomer('select customer');
        }
      }
      // console.log('applications', applications);

      const customerInAppUpdate = customerInAppCustom
        .filter((item) => (!data.external || allowedApps[item.name]?.status === true) )
        .map((d) => {
          return {
            name: d.name,
            checked: appAccessIndex.includes(d.name),
            admin: appRoleIndex.includes(`${d.name}Admin`),
          };
        });
      // console.log('customerInAppUpdate', customerInAppUpdate);

      setCustomerInApp(customerInAppUpdate);

      const customerInCustomer = companies?.map((d) => {
        return {
          id: d.id,
          name: d.companyName,
          checked: listUserGroup.includes(d.id),
        };
      });
      setCustomerInCustomer(customerInCustomer);
      data.external = data.external ? '2' : '1';
      const { appAccess, appRole, companyId, configuration, lc, permission, userGroup, ...rest } = data;
      // if (
      //   userByName?.external === false &&
      //   !JSON.parse(userByName?.permission).includes('admin') &&
      //   JSON.parse(userByName?.userGroup).length > 0
      // ) {

      // }
      if (userByName?.external === true && JSON.parse(userByName?.permission).includes('admin')) {
        setIsShowCustomer(false);
      }
      reset(rest);
    }
    // eslint-disable-next-line
  }, [selectUser, isReload]);
  useEffect(() => {
    setUserLogin(userByName?.user);
  }, [userByName]);
  useEffect(() => {
    if (isFirst.current) {
      const allowedApps = JSON.parse(
        companies?.find((d) => +formValues.external === 1 || d.companyName === choiceCustomer)?.allowedApps || '{}',
      );
      const customerUpdate = customerInAppCustom.filter(
        (cus) => +formValues.external === 1 || allowedApps[cus.name].status === true,
      );
      setCustomerInApp(customerUpdate);
    }
    // eslint-disable-next-line
  }, [choiceCustomer, formValues.external]);
  useEffect(() => {
    if (selectUser && userByName?.external === false && JSON.parse(userByName?.permission).includes('admin')) {
      if (isFirstExternal.current) {
        setChoiceCustomer('select customer');
        setValue('adminPermission', false);
        setValue('managerAdmin', false);
        setValue('application', false);
        setCustomerInCustomer(
          customerInCustomer.map((d) => {
            return { ...d, checked: false };
          }),
        );
      } else {
        isFirstExternal.current = true;
      }
    }
    // eslint-disable-next-line
  }, [formValues.external]);
  useEffect(() => {
    const userCheck = getUserUpdate(formValues);
    const isSame = _.isEqual(userCheck, selectUser);
    const isValid = formValues.user.trim() && formValues.password.trim() && !isSame;
    setDisabled(!isValid);
    // eslint-disable-next-line
  }, [formValues]);

  const handleOpenSiteAdmin = (e) => {
    const data = customerInApp.map((d) => {
      if (d.name === e.target.dataset.id && e.target.dataset.change === 'checked') {
        d.checked = e.target.checked;
        if (e.target.checked === false) {
          d.admin = e.target.checked;
        }
      } else if (d.name === e.target.dataset.id && e.target.dataset.change === 'admin') {
        d.admin = e.target.checked;
      }
      return d;
    });
    setCustomerInApp(data);
  };

  const handleAddCompany = (e) => {
    const data = customerInCustomer.map((d) => {
      if (d.name === e.target.dataset.id) {
        d.checked = e.target.checked;
      }
      return d;
    });
    setCustomerInCustomer(data);
  };

  const getUserUpdate = (data) => {
    const { user, password, email, fullName, adminPermission, application, external, managerAdmin } = data;
    const userUpdate = {
      user,
      password: btoa(password),
      email,
      fullName,
      permission: !adminPermission ? JSON.stringify([]) : JSON.stringify(['admin']),
      userGroup: JSON.stringify(
        managerAdmin
          ? customerInCustomer
            .filter((d) => d.checked === true)
            .map((d) => d.id)
            .sort((a, b) => a - b)
          : [],
      ),
      appAccess: JSON.stringify(application ? customerInApp.filter((d) => d.checked === true).map((d) => d.name) : []),
      appRole: JSON.stringify(
        application ? customerInApp.filter((d) => d.admin === true).map((d) => `${d.name}Admin`) : [],
      ),
      external: +external === 2,
      // companyId: companies?.find((d) => d.companyName === choiceCustomer)?.id || 0,
      companyId: +external === 1 ? 0 : companies?.find((d) => d.companyName === choiceCustomer)?.id || 0,
      lc: false,
      configuration: selectUser?.configuration,
    };
    return userUpdate;
  };
  const onSubmit = (data) => {
    const userUpdate = getUserUpdate(data);
    fetchAPIPut('User', userUpdate, queryClient, ['users', 'quantityStatistics']);
    reset();
  };

  if (isLoadingCompanies) {
    return <div>Loading...</div>;
  }

  return (
    <ContainerModal
      idModal={idModal}
      title={title}
      listButton={[
        { name: 'CANCEL' },
        {
          name: 'SAVE',
          colorButton: 'btn-primary',
          isHandle: handleSubmit(onSubmit),
          disabled: disabled,
        },
      ]}
    >
      <Tablist
        listTab={[
          { name: 'Information', linkTab: 'inforCR' },
          { name: 'User Setting', linkTab: 'setting' },
          {
            name: 'App Setting',
            linkTab: 'appSetting',
            isDisabled: !formValues.application,
          },
          {
            name: 'Customer Setting',
            linkTab: 'CusSite',
            isDisabled: !formValues.managerAdmin,
          },
        ]}
        dependency={selectUser}
        parentId={idModal}
      />
      <div className="tab-content tabs card-block">
        {/* information */}
        <div className="tab-pane active" id="inforCR" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="inforCR" role="tabpanel">
              <FormRow label="Name">
                <input type="text" className="form-control" {...register('user')} readOnly />
              </FormRow>
              <FormRow label="Password">
                <input
                  type="password"
                  className="form-control"
                  {...register('password', {
                    required: "Password can't blank !!!",
                  })}
                  placeholder="Enter your password"
                  onBlur={() => trigger('password')}
                />
                {errors.password && <span style={{ color: 'red', margin: '0' }}>{errors.password.message}</span>}
              </FormRow>
              <FormRow label="Email">
                <input type="text" className="form-control" {...register('email')} placeholder="Enter your email" />
              </FormRow>
              <FormRow label="Full Name">
                <input
                  type="text"
                  className="form-control"
                  {...register('fullName')}
                  placeholder="Enter your full name"
                />
              </FormRow>
            </div>
          </div>
        </div>
        {/* setting */}
        <div className="tab-pane" id="setting" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="PnDCR" role="tabpanel">
              <div>
                <div className="form-group row">
                  <div className="col">
                    <label className=" col-form-label">User Type</label>
                  </div>
                  <div
                    className={`col  dis-center mg-auto ${selectUser?.user !== userLogin && isInternalBasic ? 'hidden' : ''
                      }`}
                  >
                    <input
                      type="radio"
                      value={1}
                      id="Internal"
                      {...register('external')}
                      disabled={selectUser?.user === userLogin}
                    />
                    <span>Internal</span>
                  </div>
                  <div className="col  dis-center">
                    <input
                      type="radio"
                      value={2}
                      id="External"
                      {...register('external')}
                      disabled={selectUser?.user === userLogin}
                    />
                    <span>External</span>
                  </div>
                  <div className={`col ${selectUser?.user !== userLogin && isInternalBasic ? '' : 'hidden'}`}></div>
                </div>
              </div>
              <div className={`${+formValues.external !== 0 ? '' : 'hidden'}`}>
                <FormRow
                  label={`${+formValues.external === 1 ? 'TLS Admin' : 'Customer Admin'}`}
                  hidden={+formValues.external !== 0}
                  slCk={true}
                >
                  <input
                    type="checkbox"
                    value={`adminPermission${+formValues.external === 2 ? 'External' : 'Internal'}`}
                    {...register('adminPermission')}
                    disabled={selectUser?.user === userLogin}
                  />
                </FormRow>
                <FormRow
                  label="App Access"
                  hidden={+formValues.external === 1 || choiceCustomer !== 'select customer' || isExternalAdmin}
                  slCk={true}
                >
                  <input type="checkbox" {...register('application')} />
                </FormRow>
                {isShowCustomer && (
                  <FormRow
                    label={`${+formValues.external === 1 ? 'Customer Admin' : 'User of'}`}
                    type={'drop'}
                    hidden={formValues.adminPermission !== 'adminPermissionInternal'}
                    slCk={+formValues.external === 1}
                  >
                    <RenderIf isTrue={+formValues.external === 1}>
                      <input type="checkbox" {...register('managerAdmin')} />
                    </RenderIf>
                    <RenderIf isTrue={+formValues.external === 2}>
                      <div
                        className="btn-dropdown"
                        style={{ maxWidth: 'none', marginLeft: '35px' }}
                        onClick={() => setIsShowRetention(!isShowRetention)}
                      >
                        {choiceCustomer}
                        {isShowRetention ? (
                          <i className="icofont icofont-rounded-up"></i>
                        ) : (
                          <i className="icofont icofont-rounded-down"></i>
                        )}
                      </div>
                      {isShowRetention && (
                        <div
                          className="body-dropdown"
                          style={{ minWidth: '54.5%', maxWidth: '100%', marginLeft: '35px' }}
                        >
                          {companies
                            ?.filter(
                              (d) =>
                                (!userByName.external && JSON.parse(userByName.permission)?.includes('admin')) ||
                                JSON.parse(userByName.userGroup || '[]')?.includes(d.id),
                            )
                            ?.map((data) => (
                              <div
                                key={data.companyName}
                                className="active-dropdown"
                                onClick={() => {
                                  isFirst.current = true;
                                  setChoiceCustomer(data.companyName);
                                  setIsShowRetention(!isShowRetention);
                                }}
                              >
                                {data.companyName}
                              </div>
                            ))}
                        </div>
                      )}
                    </RenderIf>
                  </FormRow>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="appSetting" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="AppSite" role="tabpanel">
              {customerInApp?.map((data) =>
              (
                <Fragment key={data.name}>
                  <div className="form-group">
                    <div className="ds-flex">
                      <input
                        type="checkbox"
                        checked={data.checked}
                        data-id={data.name}
                        data-change="checked"
                        onChange={handleOpenSiteAdmin}
                        disabled={(data.name === 'TnT' ? numberUser.reTrack - userUse.reTrack < 1 : data.name === 'PND' ? numberUser.reMail - userUse.reMail < 1 : false) && data.checked === false}
                      />
                      <label className=" col-form-label mg-5">
                        {data.name === 'PND' ? 'reMail' : data.name === 'TnT' ? 'reTrack' : data.name}
                      </label>
                    </div>
                  </div>
                  <RenderIf isTrue={data.checked}>
                    <div className="form-group row  row">
                      <div className="col-sm-3">
                        <label className=" col-form-label">Admin</label>
                      </div>
                      <div className="col mg-auto">
                        <input
                          type="checkbox"
                          checked={data.admin}
                          data-id={data.name}
                          data-change="admin"
                          onChange={handleOpenSiteAdmin}
                        />
                        <span></span>
                      </div>
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={customerInApp.length === 0}>
                    <p>No license</p>
                  </RenderIf>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="tab-pane" id="CusSite" role="tabpanel">
          <div className={`modal-body text-left row `}>
            <div className="row tab-pane" id="CusSiteAdd" role="tabpanel">
              {customerInCustomer
                ?.filter(
                  (d) =>
                    JSON.parse(userByName?.permission).includes('admin') ||
                    JSON.parse(userByName?.userGroup).includes(d.id),
                )
                .map((data) => (
                  <div key={data.name} className="form-group">
                    <div className="ds-flex">
                      <input type="checkbox" data-id={data.name} checked={data.checked} onChange={handleAddCompany} />
                      <label className=" col-form-label">{data.name}</label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </ContainerModal>
  );
};

export default ModalEditUser;
