const images = {
  noImage: require('./no-Image.png'),
  logoTLS: require('./logoTLS_singleBlack.png'),
  logoTLS1: require('./logoTLSsingle.png'),
  logoReSort: require('./logoReSort_singleBlack.png'),
  reScaleLogo: require('./reScaleLogo_singleBlack.png'),
  reScaleLogo2: require('./reScaleLogo2.png'),
};

export default images;
