import { useState, useRef, useEffect } from 'react';
import { useStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import { getSecureItem } from '../../component/SecureStorage/secureStorage';
import { hubConnectionPnD } from '../../services/connectHub';

const Widget = ({
  title = 'Widget title',
  label = 'none',
  data = [],
  data_2 = [],
  data_3 = [],
  isHasDropbox = false,
  isHaveCheckBox = false,
  setIsCheckValue,
  setTypeChange,
  valueTime,
  customStartTime,
  customEndTime,
  isLinkActive = true,
  dataWidgetJob = [],
  setIsLoadingWidget = true,
}) => {
  const { state } = useStore();
  const { hubConnection } = state;
  const navigate = useNavigate();

  const isCheckLinkRef = useRef(false);

  const [isOpenDrop, setIsOpenDrop] = useState(false);
  const [valueDrop, setValueDrop] = useState('Day');
  const [formattedStartTime, setFormattedStartTime] = useState('');
  const [formattedEndTime, setFormattedEndTime] = useState('');

  useEffect(() => {
    if (data?.startTime) {
      const startTime = new Date(data.startTime);
      setFormattedStartTime(startTime.toLocaleString());

      const endTime = new Date();
      setFormattedEndTime(endTime.toLocaleString());
    }
  }, [data]);

  const handleOpenDrop = () => {
    if (!isCheckLinkRef.current?.checked) {
      setIsOpenDrop((prev) => !prev);
    }
  };
  const handleLoadingdata = () => {
    if (data === null) {
      setIsLoadingWidget(true);
    }
  };

  const handleValueDrop = (e) => {

    const selectedValue = e.target.id;
    const allowedValues = ['OneDay', 'OneWeek', 'OneMonth'];

    if (allowedValues.includes(selectedValue)) {
      setTypeChange(2);
      setValueDrop(e.target.innerHTML);
      setIsOpenDrop(false);

      const companyname = document.getElementById('slCompany');
      const companyName = companyname ? companyname.options[companyname.selectedIndex]?.text : null;
      if (hubConnectionPnD && hubConnection) {
        hubConnectionPnD
          .invoke('GetJobDetailsFromCompanyAsync', companyName, selectedValue)
          .then(() => console.log('Data rendered'))
          .catch((err) => console.error('SignalR Error:', err));
      }
    } else {
      setIsOpenDrop(false);
    }
  };

  const handleLinkDashboard = () => {
    setIsCheckValue(isCheckLinkRef.current?.checked);
  };

  const handleLinkToSearchJob = (status) => {
    const startTimes = data.map((item) => item.startTime).filter(Boolean);
    const minStartTime = startTimes.length > 0 ? Math.min(...startTimes) : Date.now();

    const startTimes4All = data_2.map((item) => item.startTime).filter(Boolean);
    const minStartTime4All = startTimes.length > 0 ? Math.min(...startTimes) : Date.now();

    let startTime;
    let endTime = Date.now() + 24 * 60 * 60 * 1000;

    switch (valueTime) {
      case 'OneDay':
        startTime = Date.now();
        break;
      case 'OneWeek':
        startTime = Date.now() - 7 * 24 * 60 * 60 * 1000;
        break;
      case 'OneMonth':
        startTime = Date.now() - 30 * 24 * 60 * 60 * 1000;
        break;
      case 'OneYear':
        startTime = Date.now() - 365 * 24 * 60 * 60 * 1000;
        break;
      case 'custom-date-chart':
        startTime = customStartTime ? new Date(customStartTime).getTime() : minStartTime;
        endTime = customEndTime ? new Date(customEndTime).getTime() : endTime;
        break;
      case 'AllTime':
        // For 'AllTime', set startTime to minStartTime and endTime to current time.
        startTime = 1704067200000;
        endTime = Date.now() + 24 * 60 * 60 * 1000;

        break;
      default:
        startTime = minStartTime;
        break;
    }

    navigate(
      `/searchjob?startTime=${startTime}&endTime=${endTime}&status=${status}&companyName=${getSecureItem('cComName')}`,
    );
  };

  return (
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col">
          <div className="ds-flex al-center header-widget">
            <div className="col-6">
              <h6 className="m-b-25 fw-700">{title}</h6>
            </div>
            {isHasDropbox && (
              <div className="col-6">
                <div className="dropbox-block drop-first">
                  <button
                    className={`btn btn-dropbox ${isCheckLinkRef.current?.checked ? 'disable' : ''}`}
                    onClick={handleOpenDrop}
                  >
                    {valueDrop}
                    <i className={`icofont icofont-rounded-${isOpenDrop ? 'down' : 'right'} icon-drop`}></i>
                  </button>
                  {isOpenDrop && (
                    <div className="dropbox-content">
                      <div id="OneDay" onClick={handleValueDrop}>
                        Day
                      </div>
                      <div id="OneWeek" onClick={handleValueDrop}>
                        Week
                      </div>
                      <div id="OneMonth" onClick={handleValueDrop}>
                        Month
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row text-center">
            {/* Display Jobs if title is not Active Jobs */}
            {title !== 'Active Jobs' && (
              <div className="col">
                <h3
                  className={`f-w-700 text-c-blue ${!isLinkActive ? 'disabled-link' : ''}`}
                  onClick={() => handleLinkToSearchJob('Completed')}
                  style={{ cursor: isLinkActive ? 'pointer' : 'not-allowed' }}
                >
                  {dataWidgetJob ? data.length : 0}
                </h3>
                <p
                  className={`m-b-0 text-c-blue ${!isLinkActive ? 'disabled-link' : ''}`}
                  onClick={() => handleLinkToSearchJob('Completed')}
                  style={{ cursor: isLinkActive ? 'pointer' : 'not-allowed' }}
                >
                  {data_3.length > 0 ? 'Completed' : label !== 'none' ? label : 'Jobs'}
                </p>
              </div>
            )}

            {/* Display Processing if title is not All Jobs */}
            {title !== 'All Jobs' && (
              <div className="col">
                <h3
                  className={`f-w-700 ${!isLinkActive ? 'disabled-link' : ''}`}
                  onClick={() => handleLinkToSearchJob('Processing')}
                  style={{ cursor: isLinkActive ? 'pointer' : 'not-allowed' }}
                >
                  {data_2.length}
                </h3>
                <p className="m-b-0">Processing</p>
              </div>
            )}

            {/* Display Failed if title is not Active   Jobs */}
            {title !== 'Active Jobs' && (
              <div className="col">
                <h3
                  className="f-w-700 text-c-red"
                  onClick={() => handleLinkToSearchJob('Failed')}
                  style={{ cursor: isLinkActive ? 'pointer' : 'not-allowed' }}
                >
                  {data_3.length || 0}
                </h3>
                <p className="m-b-0 text-c-red">Failed</p>
              </div>
            )}
          </div>

          {isHaveCheckBox && (
            <div className="row">
              <div className="col" style={{ position: 'relative' }}>
                <input type="checkbox" name="link_to_analys_chart" onClick={handleLinkDashboard} ref={isCheckLinkRef} />
                <span className="span-checkBox">Link to analysis chart</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Widget;
