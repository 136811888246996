import images from '../../assets/images';
import { Loading } from '../../component';

const WidgetTrack = ({ keyTracking, loading, logo, textTrack, objectTracking }) => {
  return (
    <div className="card comp-card bg-white-l">
      {loading && <Loading />}
      <div className="card-body pos-relative">
        <div className="row align-items-center" style={{ visibility: loading ? 'hidden' : 'visible' }}>
          <div className="col">
            <h5 className="m-b-25 fw-700">
              <img src={images[logo]} className="img-30 m-r-5" alt="Logo" />
              {textTrack}
            </h5>
            <div className="row text-center">
              {objectTracking &&
                objectTracking.map((o, index) => (
                  <div
                    key={`${keyTracking}-${index}`}
                    className="col"
                    style={{ color: `var(${o.color ?? '--bs-blue'})` }}
                  >
                    <h3 className={`f-w-700`}>{o.number || 0}</h3>
                    <p className={`m-b-0`}>{o.text || 0}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WidgetTrack.displayName = 'WidgetTrack';

export default WidgetTrack;
