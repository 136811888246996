import Cookies from 'js-cookie';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ModalAddUser, ModalDelete, ModalEditUser, OpenModal, Widget } from '../../component';
import { HeaderContent } from '../../component/page';
import { actions, handles, useStore } from '../../store';
import { useQueryAPI } from '../../hooks';
import { useFetcher } from 'react-router-dom';
function ManagerUser() {
  //store
  const { state, dispatch } = useStore();
  const { isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { runExistingScripts, fetchAPIDelete } = handles;
  const queryClient = useQueryClient();
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));
  //hook
  const { useCompanies, useUsers, useUserByName, useApplicationsLicenses } = useQueryAPI;
  //Call API
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: userByName, isLoading: isLoadingUserByName } = useUserByName(userLogin.user);
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies();
  const { data: applicationsLicenses, isLoading: isLoadingApplicationsLicenses } = useApplicationsLicenses();
  // useState
  const [usersCustom, setUsersCustom] = useState([]);
  const [companiesCustom, setCompaniesCustom] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [isInternalBasic, setIsInternalBasic] = useState(false);
  const [isExternalAdmin, setIsExternalAdmin] = useState(false);
  const [isReload, setIsReload] = useState(1);
  const [isReloadEdit, setIsReloadEdit] = useState(1);
  const [companiExternalApp, setCompaniExternalApp] = useState({});
  const [companiExternalAppTnT, setCompaniExternalAppTnT] = useState({});
  const [isAccess, setIsAccess] = useState(false);
  const [numberUser, setNumberUser] = useState({})
  const [userUse, setUserUse] = useState({})
  //useEffect


  useEffect(() => {
    if (userByName) {
      if (!userByName?.external && JSON.parse(userByName?.permission).includes('admin')) {
        setIsAccess(true);
      } else if (!userByName?.external && JSON.parse(userByName?.userGroup).length > 0) {
        setIsAccess(true);
      } else if (userByName?.external && JSON.parse(userByName?.permission).includes('admin')) {
        setIsAccess(true);
      } else {
        setIsAccess(false);
      }
    }
  }, [userByName]);
  useEffect(() => {
    const dTable = usersCustom?.map((data) => {
      const customerIndex = companiesCustom || [];
      const isAdminInternal = data.external === false && JSON.parse(data.permission).includes('admin');
      const isAdminExternal = data.external === true && JSON.parse(data.permission).includes('admin');
      let cust =
        data.external === true && data.companyId === 0
          ? 'NO COMPANY'
          : data.companyId !== 0 && customerIndex.length > 0
            ? customerIndex?.find((c) => c.id === data.companyId)?.companyName
            : '----';
      if (data.companyId === undefined) {
        cust = 'delete';
      }
      const group = JSON.parse(data.userGroup)?.map((idGroup) => {
        return customerIndex.find((d) => d.id === idGroup)?.companyName;
      });

      return {
        ...data,
        companyId: cust,
        userGroup: isAdminExternal ? [cust] : isAdminInternal ? ['All customer'] : group,
      };
    });
    let check = true;
    for (let i = 0; i < dTable.length; i++) {
      if (dTable[i].companyId === undefined) {
        check = false;
      }
    }
    if (check) {
      const dataTable = initDataTable('tableNewJob', dTable);
      return () => {
        if (dataTable) {
          dataTable.destroy();
        }
      };
    }
    // eslint-disable-next-line
  }, [usersCustom, companiesCustom]);

  useEffect(() => {
    if (userByName?.companyId !== 0 && userByName?.external) {
      const license = JSON.parse(
        applicationsLicenses?.find((app) => app?.appName === 'PND')?.companyAllowedUsers || '[]',
      );
      const reMailUser = license.find((li) => li?.CompanyId === userByName?.companyId);
      setCompaniExternalApp(license.find((li) => li?.CompanyId === userByName?.companyId));

      const licenseTnT = JSON.parse(
        companies?.find((app) => userByName?.companyId === app.id)?.allowedApps || '[]',
      );
      const numberUser = licenseTnT?.TnT?.description?.allowedUsers;
      
      const userTnTCompany = users?.filter(data => data.companyId === userByName?.companyId && data.appAccess.includes('TnT'));
      setCompaniExternalAppTnT({ totalUser: numberUser, userUse: userTnTCompany?.length });
      setNumberUser({reMail : reMailUser?.AllowedUsers, reTrack : numberUser})
      setUserUse({reMail : reMailUser?.UsedUsers, reTrack : userTnTCompany?.length})
    }
  }, [userByName, applicationsLicenses, companies]);

  useEffect(() => {
    if (userByName && users && companies) {
      let usersUpdate = users;
      const config = JSON.parse(userByName.userGroup);
      const isAdmin = userByName.external === false && JSON.parse(userByName.permission).includes('admin');
      // Internal admin
      if (userByName.external === false && config.length > 0) {
        //Internal basic (your account & external)
        usersUpdate = users.filter((d) => d.user === userByName.user || config.includes(d.companyId));
      } else if (userByName.external === true && JSON.parse(userByName.permission).includes('admin')) {
        usersUpdate = users.filter((d) => d.companyId === userByName.companyId);
        setIsExternalAdmin(true);
      }
      setCompaniesCustom(companies.filter((d) => userByName.companyId === 0 || userByName.companyId === d.id));
      setIsInternalBasic(!isAdmin);
      // setUsersCustom(usersUpdate?.filter((d) => d.user !== userByName.user));
      setUsersCustom(usersUpdate);
    }
  }, [users, userByName, companies]);

  const initDataTable = (selector, data) => {
    const tableElement = document.getElementsByClassName(selector);
    if (!tableElement) {
      return;
    }
    const columns = [
      {
        data: 'user',
        title: 'Username',
      },
      { data: 'fullName', title: 'Fullname' },
      {
        data: 'external',
        title: 'User Type',
        render: function (data) {
          return data ? 'External' : 'Internal';
        },
      },
      {
        data: 'permission',
        title: 'TLS Role',
        render: function (data) {
          if (data) {
            try {
              const parsedData = JSON.parse(data);
              return parsedData.map((item) => `<div style="margin-right: 5px">Admin</div>`).join('');
            } catch (error) {
              console.error('Error parsing permission data:', error);
              return data;
            }
          } else {
            return data;
          }
        },
      },
      {
        data: 'appRole',
        title: 'App Admin',
        // class: 'table-wrap-column',
        render: function (data) {
          if (data) {
            try {
              const parsedData = JSON.parse(data);
              let string = '';
              for (let index = 0; index < parsedData.length; index++) {
                string += parsedData[index];
              }
              string = string
                .replace('reScale', `<div class=" icon-row-table reScale-icon" title="reScale"></div>`)
                .replace('PND', `<div class=" icon-row-table reMail-icon" title="reMail"></div>`)
                .replace('reSort', `<div class=" icon-row-table reSort-icon" title="reSort"></div>`)
                .replace('TnT', `<div class=" icon-row-table reTrack-icon" title="reTrack"></div>`)
                .replaceAll(',', ' ')
                .replaceAll('Admin', '');
              string = `<div style="display: flex; flex-wrap: warp">` + string + `</div>`;
              return string;
            } catch (error) {
              console.error('Error parsing appRole data:', error);
              return data;
            }
          } else {
            return data;
          }
        },
      },
      {
        data: 'appAccess',
        title: 'App Access',
        // class: 'table-wrap-column',
        render: function (data) {
          if (data) {
            try {
              const parsedData = JSON.parse(data || '[]') + '';
              let string = '';
              for (let index = 0; index < parsedData.length; index++) {
                string += parsedData[index];
              }
              string = string
                .replace('reScale', `<div class=" icon-row-table reScale-icon" title="reScale"></div>`)
                .replace('PND', `<div class=" icon-row-table reMail-icon" title="reMail"></div>`)
                .replace('reSort', `<div class=" icon-row-table reSort-icon" title="reSort"></div>`)
                .replace('TnT', `<div class=" icon-row-table reTrack-icon" title="reTrack"></div>`)
                .replaceAll(',', ' ');
              string = `<div style="display: flex; flex-wrap: warp">` + string + `</div>`;
              return string;
              // return parsedData
              //   .map(
              //     (item) =>
              //       `<div class="btn btn-primary" style="text-transform: none;margin-right: 5px;cursor : default">${
              //         item === 'PND' ? 'reMail' : item === 'TnT' ? 'reTrack' : item
              //       }</div>`,
              //   )
              //   .join('');
            } catch (error) {
              console.error('Error parsing appAccess data:', error);
              return data;
            }
          } else {
            return data;
          }
        },
      },
      {
        data: 'companyId',
        title: 'User of',
      },
      {
        data: 'userGroup',
        title: 'Admin of',
        // class: 'table-wrap-column',
        render: function (data) {
          if (data) {
            try {
              return data
                .map(
                  (item) =>
                    `<div class="btn btn-primary" style="margin-right: 5px;cursor : default  ;margin-bottom: 5px">${item}</div>`,
                )
                .join('');
            } catch (error) {
              console.error('Error parsing appAccess data:', error);
              return data;
            }
          } else {
            return data;
          }
        },
      },
      {
        data: 'user',
        title: 'Setting',
        class: 'btn-edit',
        orderable: false,
        render: function (data, type, row) {
          const column0Value = row['user'];
          return !(column0Value === userByName.user)
            ? `<div class='col' data-id="${column0Value}">
            <button type="button" class="btn waves-effect waves-light edit-btn" data-bs-toggle="modal" data-bs-target="#settingApps">
              <i class="icofont icofont-pen-alt-4"></i>
            </button>
            <button type="button" class="btn waves-effect waves-light" style="color: red" data-bs-toggle="modal" data-bs-target="#deleteUser">
              <i class="icofont icofont-trash"></i>
            </button>
          </div>`
            : `<div class='col' data-id="${column0Value}">
            <button type="button" class="btn waves-effect waves-light edit-btn" data-bs-toggle="modal" data-bs-target="#settingApps">
              <i class="icofont icofont-pen-alt-4"></i>
            </button>
          </div>`;
        },
      },
    ];
    let dataTable = window.$(`.${selector}`).DataTable({
      responsive: true,
      data: data,
      width: '100%',
      columns: columns,
      columnDefs: [
        // {
        //   // orderable: false,
        //   targets: 0,
        // },
      ],
      order: [],
    });
    // click
    dataTable.off('click');

    dataTable.on('click', 'tbody td.btn-edit .col', function (data) {
      const userIndex = data.currentTarget.dataset.id;
      setIsReloadEdit((prev) => (prev === 2 ? prev - 1 : prev + 1));
     
      if (userIndex) {
        const dChoice = usersCustom.find((d) => d.user === userIndex);
        setSelectUser(dChoice);
      }
    });
    dataTable.on('click', 'tbody td.child .col', function (data) {
      const userIndex = data.currentTarget.dataset.id;
      setIsReloadEdit((prev) => (prev === 2 ? prev - 1 : prev + 1));
     
      if (userIndex) {
        const dChoice = usersCustom.find((d) => d.user === userIndex);
        setSelectUser(dChoice);
      }
    });
    return dataTable;
  };

  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript, true);
    // eslint-disable-next-line
  }, []);
  // onChange Connect

  const handleDeleteUser = () => {
    fetchAPIDelete(`User/${selectUser.user}`, queryClient, ['users', 'applicationsLicenses']);
  };

  if (isLoadingCompanies & isLoadingUsers && isLoadingUserByName && isLoadingApplicationsLicenses) {
    return <div>Loading...</div>;
  }
  const handleResetModal = () => {
    setIsReload((prev) => (prev === 2 ? prev - 1 : prev + 1));
  };

  return (
    <div className="pcoded-content">
      <HeaderContent
        title={'Users Setting'}
        breadcrumb={'user-and-roles'}
        link={'/users'}
        icon={'fa fa-users'}
        typeIcon={'fa'}
      />
      {isAccess ? (
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-block">
                        <OpenModal idModal={'addUser'} title="Create new user" onHandle={handleResetModal}>
                          Create new user
                        </OpenModal>
                        {JSON.parse(userByName?.permission).includes('admin') &&
                          applicationsLicenses
                            ?.filter((appLicense) => {
                              let isApp;
                              if (!userByName?.external) {
                                isApp = true;
                              } else if (userByName?.external) {
                                const tagUser = appLicense?.tagUser
                                  ?.replace(/"/g, '')
                                  .split(',')
                                  .map((item) => item.trim())
                                  .includes('External');
                                let company = JSON.parse(
                                  companies?.find((com) => com.id === userByName?.companyId)?.allowedApps,
                                );
                                const obj = Object.keys(company).filter((key) => company[key].status === true);
                                isApp = obj.includes(appLicense.appName) && tagUser;
                              }
                              return isApp;
                            })
                            .map((data) => {
                              let re = ``;
                              if (userByName?.external && data.appName.toUpperCase() === 'PND') {
                                re = ` ${data.appName === 'PND' ? 'reMail' : data.appName === 'TnT' ? 'reTrack' : data.appName
                                  } : ${companiExternalApp?.UsedUsers}/${companiExternalApp?.AllowedUsers}`;
                              } else if (userByName?.external && data.appName.toUpperCase() === 'TNT') {
                                re = ` ${data.appName === 'PND' ? 'reMail' : data.appName === 'TnT' ? 'reTrack' : data.appName
                                  } : ${companiExternalAppTnT?.userUse}/${companiExternalAppTnT?.totalUser}`;
                              } else {
                                re = ` ${data.appName === 'PND' ? 'reMail' : data.appName === 'TnT' ? 'reTrack' : data.appName
                                  } : ${data.usedUsers}/${data.allowedUsers}`;
                              }
                              return (
                                <span
                                  key={data.appName}
                                  style={{
                                    float: 'right',
                                    paddingRight: '7px',
                                    borderRight: '5px solid #cccc',
                                    paddingLeft: '7px',
                                  }}
                                >
                                  {re}
                                </span>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-block">
                        <div className="card-block">
                          <div className="dt-responsive table-responsive">
                            <table
                              // id="checkbox-select"
                              className="table table-striped table-bordered nowrap tableNewJob"
                              style={{ width: '100%' }}
                            ></table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Can access this site, please go back !!!</div>
      )}

      {/* <ModalAddUser /> */}
      <ModalAddUser
        idModal={'addUser'}
        title={'Create new user'}
        isInternalBasic={isInternalBasic}
        isReload={isReload}
      />
      {/* <ModalEditUser /> */}
      <ModalEditUser
        idModal={'settingApps'}
        title={'Setting'}
        selectUser={selectUser}
        isInternalBasic={isInternalBasic}
        isUserExternalAdmin={isExternalAdmin}
        userByName={userByName}
        isReload={isReloadEdit}
        numberUser={numberUser}
        userUse={userUse}

      />
      {/* <ModalDeleteUser /> */}
      <ModalDelete
        idModal={'deleteUser'}
        title={'Delete Customer'}
        textDelete={'Do you want delete this user ?'}
        handleDelete={handleDeleteUser}
      />
    </div>
  );
}

export default ManagerUser;
