import { NavLink } from 'react-router-dom';
import './job.scss';
function Products() {
  return (
    <div className={`pcoded-content`}>
      <div className="page-header card">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <div className="page-header-title">
              <i className="feather icon-grid bg-c-blue"></i>
              <div className="d-inline">
                <h5>Products</h5>
                <span></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="page-header-breadcrumb">
              <ul className=" breadcrumb breadcrumb-title breadcrumb-padding">
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard">
                    <i className="feather icon-home"></i>
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard/monitoring">Products</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="col-lg-12 col-xl-12">
                <div className="card"></div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div class="card latest-update-card">
                    <div class="card-header">
                      <h5>reProducts</h5>
                      <div class="card-header-right"></div>
                    </div>
                    <div class="card-block">
                      <div class="latest-update-box">
                        <div class="row p-t-20 p-b-30">
                          <div class="col-auto text-end update-meta p-r-0">
                            <i class="feather icon-briefcase bg-c-red update-icon"></i>
                            {/* <img src="../files/assets/images/avatar-4.jpg" alt="user image" class="img-radius img-40 align-top m-r-15 update-icon" /> */}
                          </div>
                          <div class="col p-l-5">
                            <div class="row">
                              <div class="col-xl-4 col-md-12">
                                <h1>reScale</h1>
                                <p class="text-muted m-b-0">Description of product</p>
                                <a href={'http://' + window.location.hostname + ':3000/#/dashboard'}>
                                  <h6>Access</h6>
                                </a>
                                <a href="#!">
                                  <h6>Contact us</h6>
                                </a>
                              </div>
                              <div class="col-xl-6 col-md-12">
                                <section class="gallery1">
                                  <div class="gallery1__item">
                                    <input
                                      type="radio"
                                      id="img-1"
                                      defaultChecked
                                      name="gallery1"
                                      class="gallery1__selector"
                                    />
                                    <img class="gallery1__img" src="https://picsum.photos/id/1015/600/400.jpg" alt="" />
                                    <label for="img-1" class="gallery1__thumb">
                                      <img src="https://picsum.photos/id/1015/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery1__item">
                                    <input type="radio" id="img-2" name="gallery1" class="gallery1__selector" />
                                    <img class="gallery1__img" src="https://picsum.photos/id/1039/600/400.jpg" alt="" />
                                    <label for="img-2" class="gallery1__thumb">
                                      <img src="https://picsum.photos/id/1039/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery1__item">
                                    <input type="radio" id="img-3" name="gallery1" class="gallery1__selector" />
                                    <img class="gallery1__img" src="https://picsum.photos/id/1057/600/400.jpg" alt="" />
                                    <label for="img-3" class="gallery1__thumb">
                                      <img src="https://picsum.photos/id/1057/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery1__item">
                                    <input type="radio" id="img-4" name="gallery1" class="gallery1__selector" />
                                    <img class="gallery1__img" src="https://picsum.photos/id/106/600/400.jpg" alt="" />
                                    <label for="img-4" class="gallery1__thumb">
                                      <img src="https://picsum.photos/id/106/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row p-b-30">
                          <div class="col-auto text-end update-meta p-r-0">
                            <i class="feather icon-briefcase bg-c-red update-icon"></i>
                          </div>
                          <div class="col p-l-5">
                            <div class="row">
                              <div class="col-xl-4 col-md-12">
                                <h1>reSort</h1>
                                <p class="text-muted m-b-0">Description of product</p>
                                <a href={'http://' + window.location.hostname + ':3000/#/dashboard'}>
                                  <h6>Access</h6>
                                </a>
                                <a href="#!">
                                  <h6>Contact us</h6>
                                </a>
                              </div>
                              <div class="col-xl-6 col-md-12">
                                <section class="gallery2">
                                  <div class="gallery__item">
                                    <input
                                      type="radio"
                                      id="img-11"
                                      defaultChecked
                                      name="gallery2"
                                      class="gallery2__selector"
                                    />
                                    <img class="gallery2__img" src="https://picsum.photos/id/1015/600/400.jpg" alt="" />
                                    <label for="img-11" class="gallery2__thumb">
                                      <img src="https://picsum.photos/id/1015/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery2__item">
                                    <input type="radio" id="img-21" name="gallery2" class="gallery2__selector" />
                                    <img class="gallery2__img" src="https://picsum.photos/id/1039/600/400.jpg" alt="" />
                                    <label for="img-21" class="gallery2__thumb">
                                      <img src="https://picsum.photos/id/1039/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery2__item">
                                    <input type="radio" id="img-31" name="gallery2" class="gallery2__selector" />
                                    <img class="gallery2__img" src="https://picsum.photos/id/1057/600/400.jpg" alt="" />
                                    <label for="img-31" class="gallery2__thumb">
                                      <img src="https://picsum.photos/id/1057/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery2__item">
                                    <input type="radio" id="img-41" name="gallery2" class="gallery2__selector" />
                                    <img class="gallery2__img" src="https://picsum.photos/id/106/600/400.jpg" alt="" />
                                    <label for="img-41" class="gallery2__thumb">
                                      <img src="https://picsum.photos/id/106/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row p-b-30">
                          <div class="col-auto text-end update-meta p-r-0">
                            <i class="feather icon-briefcase bg-c-red update-icon"></i>
                          </div>
                          <div class="col p-l-5">
                            <div class="row">
                              <div class="col-xl-4 col-md-12">
                                <h1>reTrack</h1>
                                <p class="text-muted m-b-0">Description of product</p>
                                <a href={'http://' + window.location.hostname + ':3000/#/dashboard'}>
                                  <h6>Access</h6>
                                </a>
                                <a href="#!">
                                  <h6>Contact us</h6>
                                </a>
                              </div>
                              <div class="col-xl-6 col-md-12">
                                <section class="gallery4">
                                  <div class="gallery4__item">
                                    <input
                                      type="radio"
                                      id="img-12"
                                      defaultChecked
                                      name="gallery4"
                                      class="gallery4__selector"
                                    />
                                    <img class="gallery4__img" src="https://picsum.photos/id/1015/600/400.jpg" alt="" />
                                    <label for="img-12" class="gallery4__thumb">
                                      <img src="https://picsum.photos/id/1015/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery4__item">
                                    <input type="radio" id="img-22" name="gallery4" class="gallery4__selector" />
                                    <img class="gallery4__img" src="https://picsum.photos/id/1039/600/400.jpg" alt="" />
                                    <label for="img-22" class="gallery4__thumb">
                                      <img src="https://picsum.photos/id/1039/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery4__item">
                                    <input type="radio" id="img-32" name="gallery4" class="gallery4__selector" />
                                    <img class="gallery4__img" src="https://picsum.photos/id/1057/600/400.jpg" alt="" />
                                    <label for="img-32" class="gallery4__thumb">
                                      <img src="https://picsum.photos/id/1057/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery4__item">
                                    <input type="radio" id="img-42" name="gallery4" class="gallery4__selector" />
                                    <img class="gallery4__img" src="https://picsum.photos/id/106/600/400.jpg" alt="" />
                                    <label for="img-42" class="gallery4__thumb">
                                      <img src="https://picsum.photos/id/106/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row p-b-30">
                          <div class="col-auto text-end update-meta p-r-0">
                            <i class="feather icon-check f-w-600 bg-c-green update-icon"></i>
                          </div>
                          <div class="col p-l-5">
                            <div class="row">
                              <div class="col-xl-4 col-md-12">
                                <h1>reArchive</h1>
                                <p class="text-muted m-b-0">Description of product</p>
                                <a href={'http://' + window.location.hostname + ':3000/#/dashboard'}>
                                  <h6>Access</h6>
                                </a>
                                <a href="#!">
                                  <h6>Contact us</h6>
                                </a>
                              </div>
                              <div class="col-xl-6 col-md-12">
                                <section class="gallery5">
                                  <div class="gallery5__item">
                                    <input
                                      type="radio"
                                      id="img-13"
                                      defaultChecked
                                      name="gallery5"
                                      class="gallery5__selector"
                                    />
                                    <img class="gallery5__img" src="https://picsum.photos/id/1015/600/400.jpg" alt="" />
                                    <label for="img-13" class="gallery5__thumb">
                                      <img src="https://picsum.photos/id/1015/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery5__item">
                                    <input type="radio" id="img-23" name="gallery5" class="gallery5__selector" />
                                    <img class="gallery5__img" src="https://picsum.photos/id/1039/600/400.jpg" alt="" />
                                    <label for="img-23" class="gallery5__thumb">
                                      <img src="https://picsum.photos/id/1039/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery5__item">
                                    <input type="radio" id="img-33" name="gallery5" class="gallery5__selector" />
                                    <img class="gallery5__img" src="https://picsum.photos/id/1057/600/400.jpg" alt="" />
                                    <label for="img-33" class="gallery5__thumb">
                                      <img src="https://picsum.photos/id/1057/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                  <div class="gallery5__item">
                                    <input type="radio" id="img-43" name="gallery5" class="gallery5__selector" />
                                    <img class="gallery5__img" src="https://picsum.photos/id/106/600/400.jpg" alt="" />
                                    <label for="img-43" class="gallery5__thumb">
                                      <img src="https://picsum.photos/id/106/150/100.jpg" alt="" />
                                    </label>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
