import Cookies from 'js-cookie';

export const isLoggedIn = () => {
  return Cookies.get('loggedInUser');
};

export const getCookie = (nameCookie) => {
  return Cookies.get(nameCookie);
};
export const removeCookie = (nameCookie) => {
  return Cookies.remove(nameCookie);
};

export const setCookie = (nameCookie, valueCookie, expirationCookie = 0) => {
  const setCookie = Cookies.set(nameCookie, valueCookie);
  return setCookie;
};

export const setCookieExpires = (nameCookie, valueCookie, expirationCookie) => {
  const setCookie = Cookies.set(nameCookie, valueCookie, {
    expires: expirationCookie,
  });
  return setCookie;
};
export const getDate = (millisecond) => {
  const timestamp = millisecond;
  const date = new Date(timestamp);
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};

export const convertToMilliseconds = (value, unit) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  if (unit === 'Hours') {
    const milliseconds = value * 60 * 60 * 1000;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Days') {
    const milliseconds = value * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Weeks') {
    const milliseconds = value * 7 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Months') {
    const milliseconds = value * 30 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  }

  return 0;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export const formatTimestampDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const createData = (timesDb, timesDoc, timesPage, type, fromTimes = 0, toTimes = 0) => {
  const data = [];
  let end, start;

  const config = {
    OneDay: {
      adjustStart: (start) => start.setHours(end.getHours() - 24),
      interval: 1000 * 60 * 60,
    },
    OneWeek: {
      adjustStart: (start) => start.setHours(end.getHours() - 24 * 7),
      interval: 1000 * 60 * 60,
    },
    OneMonth: {
      adjustStart: (start) => start.setDate(end.getDate() - 30),
      interval: 1000 * 60 * 60 * 24,
    },
    OneYear: {
      adjustStart: (start) => start.setFullYear(end.getFullYear() - 1),
      interval: 1000 * 60 * 60 * 24,
    },
  };

  const { adjustStart, interval } = config[type];
  if (!adjustStart) {
    throw new Error('Invalid type');
  }
  if (fromTimes !== 0 && toTimes !== 0) {
    start = new Date(fromTimes);
    end = new Date(toTimes);
  } else {
    end = new Date();
    start = new Date(end);
    adjustStart(start);
  }

  const timeDiff = end.getTime() - start.getTime();
  const steps = Math.floor(timeDiff / interval);

  for (let i = 0; i <= steps; i++) {
    const current = new Date(start.getTime() + i * interval);

    if (type === 'OneWeek' && ![0, 12].includes(current.getHours())) {
      continue;
    }

    const formattedDate = `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}-${String(
      current.getDate(),
    ).padStart(2, '0')}${
      ['OneDay', 'OneWeek'].includes(type) ? ` ${String(current.getHours()).padStart(2, '0')}:00` : ''
    }`;
    data.push({ date: formattedDate, market1: 0, market2: 0, market3: 0 });
  }
  // jobs
  timesDb?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market1++;
      }
    }
  });

  //documents
  timesDoc?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market2++;
      }
    }
  });

  timesPage?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market3++;
      }
    }
  });

  return data;
};

const getTypeFromTimeRange = (fromTimes, toTimes) => {
  const fromDate = new Date(fromTimes);
  const toDate = new Date(toTimes);

  if (toDate < fromDate) {
    return null;
  }

  const maxDateRange = {
    OneDay: 3,
    OneWeek: 7,
    OneMonth: 30,
    OneYear: 365,
  };

  const diffDays = (toDate - fromDate) / (1000 * 60 * 60 * 24);
  const type = Object.keys(maxDateRange).find((type) => diffDays <= maxDateRange[type]);

  return type || 'OneYear';
};

export const ChartDashBoardAllTnT = (
  dataFecth,
  documentCount = [],
  pagecount = [],
  type = 'OneDay',
  fromTimes = 0,
  toTimes = 0,
) => {
  // Validate input

  if (!Array.isArray(dataFecth)) {
    console.error('Error: dataFecth is not an array');
    return;
  }

  let adjustedType = type;

  // Adjust type based on time range if provided
  if (fromTimes !== 0 && toTimes !== 0) {
    adjustedType = getTypeFromTimeRange(fromTimes, toTimes);
  }

  // Create chart data using the given function
  const dataChart = createData(dataFecth, documentCount, pagecount, adjustedType, fromTimes, toTimes);

  // Create the chart using AmCharts
  window.AmCharts.makeChart('deal-analytic-chartTnT', {
    type: 'serial',
    theme: 'light',
    precision: 2,
    valueAxes: [
      {
        id: 'v1',
        position: 'left',
        autoGridCount: false,
        labelFunction: function (value) {
          return '$' + Math.round(value) + 'M';
        },
      },
      {
        id: 'v2',
        gridAlpha: 0,
        autoGridCount: false,
      },
    ],
    graphs: [
      {
        id: 'g1',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 30,
        lineThickness: 3,
        lineColor: '#32506D',
        type: 'smoothedLine',
        title: 'Jobs',
        useLineColorForBulletBorder: true,
        valueField: 'market1',
        balloonText: "[[title]]<br /><b style='font-size: 150%'>[[value]]</b>",
      },
      {
        id: 'g2',
        valueAxis: 'v2',
        bullet: 'diamond',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 30,
        lineThickness: 3,
        lineColor: '#DB6D67',
        type: 'smoothedLine',
        title: 'Documents',
        useLineColorForBulletBorder: true,
        valueField: 'market2',
        balloonText: "[[title]]<br /><b style='font-size: 150%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g3',
        valueAxis: 'v2',
        bullet: 'square',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 30,
        lineThickness: 3,
        lineColor: '#D2DB67',
        type: 'smoothedLine',
        title: 'Pages',
        useLineColorForBulletBorder: true,
        valueField: 'market3',
        balloonText: "[[title]]<br /><b style='font-size: 150%'>[[value]]</b>",
        hidden: true,
      },
    ],
    chartCursor: {
      pan: true,
      valueLineEnabled: true,
      valueLineBalloonEnabled: true,
      cursorAlpha: 0,
      valueLineAlpha: 0.2,
    },
    chartScrollbar: {
      autoGridCount: true,
      graph: 'g1',
      oppositeAxis: true,
      scrollbarHeight: 40,
    },
    categoryField: 'date',
    categoryAxis: {
      parseDates: true,
      minPeriod: 'hh',
      dashLength: 2,
      minorGridEnabled: true,
      dateFormats: [
        { period: 'fff', format: 'JJ:NN:SS' },
        { period: 'ss', format: 'JJ:NN:SS' },
        { period: 'mm', format: 'JJ:NN' },
        { period: 'hh', format: 'JJ:NN' },
        { period: 'DD', format: 'MMM DD' },
        { period: 'WW', format: 'MMM DD' },
        { period: 'MM', format: 'MMM' },
        { period: 'YYYY', format: 'YYYY' },
      ],
    },
    legend: {
      useGraphSettings: true,
      position: 'top',
    },
    balloon: {
      borderThickness: 1,
      shadowAlpha: 0,
    },
    dataProvider: dataChart,
  });
};

// Function to check input validity
export const checkInput = (input, data = null) => {
  let check = true;

  // Check if input is an empty string or exists in the provided data
  if (input === '') {
    check = false;
  } else if (data) {
    check = true;
    data?.map((d) => {
      if (d.intergrationName === input) {
        check = false;
      }
    });
  }

  return check;
};

export const ChartPieDeliveryChannelsTnT = (apiResponse, setIsLoadingPieChart) => {
  // Set loading state before starting chart processing
  // setIsLoadingPieChart(false);

  // Create a map to aggregate counts by delivery channel
  const channelCounts = {};

  apiResponse.forEach((item) => {
    item.deliveryChannels.forEach((channel) => {
      if (!channelCounts[channel]) {
        channelCounts[channel] = 0;
      }
      channelCounts[channel] += 1; // Increment count for each occurrence
    });
  });

  // Transform the aggregated data into a format suitable for the pie chart
  const dataTrans = Object.keys(channelCounts).map((channel) => ({
    type: channel,
    value: channelCounts[channel],
  }));
  const sorted_data = dataTrans.sort((a, b) => a.type.localeCompare(b.type));
 // console.log('datachar',sorted_data)
  // Check if AmCharts is available and the container exists
  if (window.AmCharts && document.getElementById('allocation-chartTnT')) {

    var chartPie = window.AmCharts.makeChart('allocation-chartTnT', {
      type: 'pie',
      theme: 'light',
      dataProvider: sorted_data,
      valueField: 'value',
      titleField: 'type',
      labelRadius: 0,
      pullOutRadius: 0,
      labelText: '[[type]]',
      innerRadius: '50%',
      legend: {
        enabled: true,
        position: 'right',
      },
      // Custom color palette
      colors: ['#32506D', '#67A3DB', '#D2DB67', '#DB6D67', '#5A5C4C', '#876D6C'],
      // Disable animations or other effects
      startDuration: 0, // No animation on render
    });

    // Set loading state to true once the chart has finished rendering
    chartPie.addListener('rendered', function () {
      console.log('Pie chart has finished rendering.');
      // setIsLoadingPieChart(true);
    });
  } else {
    console.error('AmCharts library is loaded or container element is missing.');
    // setIsLoadingPieChart(true);
  }
};
