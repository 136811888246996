import { useEffect } from 'react';
import {  handles } from '../../store';
import { TrackContainer, WidgetTrack, ChartTrack } from '../../component';
import { useQueryAPI } from '../../hooks';
const ReScaleWidget = () => {
    //store
    const { ChartDashBoardAll, getCookie } = handles;
    //useRef

    const userLogin = JSON.parse(getCookie('loggedInUser'));
    //hook
    const { useConfiguration, useDashboardJobsNumber } = useQueryAPI;
    //Call API
    const { data: dashboardConfig, isLoading: isLoadingDashboardConfig } = useConfiguration(
        userLogin.user,
        'dashboardConfig',
    );
    const { data: dashboardJobsNumber, isLoading: isLoadingDashboardJobsNumber } = useDashboardJobsNumber(userLogin.user);

    useEffect(() => {
        if (
            dashboardConfig?.some((c) => c.title === 'ChartJobs' && c.view) &&
            dashboardJobsNumber?.dataAnalyticChar?.length > 0
        ) {
            ChartDashBoardAll(dashboardJobsNumber.dataAnalyticChar);
        }
        // eslint-disable-next-line
    }, [dashboardJobsNumber, dashboardConfig]);

    if (isLoadingDashboardConfig || isLoadingDashboardJobsNumber) return <div>Loading...</div>;
    return (
        <TrackContainer dashboardConfig={dashboardConfig}>
            <ChartTrack
                keyTracking="ChartJobs"
                loading={isLoadingDashboardJobsNumber}
                logo="reScaleLogo"
                textTrack="Jobs Chart"
            />
            <WidgetTrack
                keyTracking="JobsAll"
                loading={isLoadingDashboardJobsNumber}
                logo="reScaleLogo"
                textTrack="All Last 24h"
                objectTracking={[
                    { number: dashboardJobsNumber?.all, text: 'All', color: '--bs-dark' },
                    {
                        number: dashboardJobsNumber?.allFailed,
                        text: 'Failed',
                        color: '--bs-red',
                    },
                ]}
            />
            <WidgetTrack
                keyTracking="Demand"
                loading={isLoadingDashboardJobsNumber}
                logo="reScaleLogo"
                textTrack="On-Demand Last 24h"
                objectTracking={[
                    {
                        number: dashboardJobsNumber?.demand,
                        text: 'All',
                        color: '--bs-dark',
                    },
                    {
                        number: dashboardJobsNumber?.demandCompleted,
                        text: 'Completed',
                    },
                    {
                        number: dashboardJobsNumber?.demandFailed,
                        text: 'Failed',
                        color: '--bs-red',
                    },
                    {
                        number: dashboardJobsNumber?.demandDuration2s,
                        text: 'Duration > 2s',
                        color: '--bs-cyan',
                    },
                ]}
            />
            <WidgetTrack
                keyTracking="Batch"
                loading={isLoadingDashboardJobsNumber}
                logo="reScaleLogo"
                textTrack="Batch Last 24h"
                objectTracking={[
                    {
                        number: dashboardJobsNumber?.batch,
                        text: 'All',
                        color: '--bs-dark',
                    },
                    {
                        number: dashboardJobsNumber?.batchCompleted,
                        text: 'Completed',
                    },
                    {
                        number: dashboardJobsNumber?.batchFailed,
                        text: 'Failed',
                        color: '--bs-red',
                    },
                ]}
            />
            <WidgetTrack
                keyTracking="Ticket"
                loading={isLoadingDashboardJobsNumber}
                logo="reScaleLogo"
                textTrack="Ticket"
                objectTracking={[
                    { number: dashboardJobsNumber?.open, text: 'Open', color: '--bs-dark' },
                    {
                        number: dashboardJobsNumber?.close,
                        text: 'Close',
                        color: '--bs-dark',
                    },
                ]}
            />
        </TrackContainer>

    );
}

export default ReScaleWidget;