import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import Tooltip from '../../layouts/components/Header/Tooltip';
import { useStore } from '../../store';
import config from '../../config';
const DropDownMenu = ({}) => {
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));
  const { state } = useStore();
  const { hubConnection } = state;
  const [userData, setuserData] = useState({});
  const [canAccessUser, setCanAccessUser] = useState(false);
  const [canAccessCustomer, setCanAccessCustomer] = useState(false);
  const [canAccessApp, setCanAccessApp] = useState(false);
  useEffect(() => {
    if (hubConnection) {
      //invoke
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));
      hubConnection.on('SendReadEmailUserByUserName', (param) => {
        setuserData(param);
        let config = JSON.parse(param.userGroup);
        if (param.user === 'ADMIN') {
          setCanAccessApp(true);
          setCanAccessUser(true);
          setCanAccessCustomer(true);
        } else if (param.external === false && JSON.parse(param.permission).includes('admin')) {
          setCanAccessUser(true);
          setCanAccessCustomer(true);
        } else if (param.external === false && config.length > 0) {
          setCanAccessUser(true);
        } else if (param.external === true && JSON.parse(param.permission).includes('admin')) {
          setCanAccessUser(true);
        } else {
          setCanAccessUser(false);
          setCanAccessCustomer(false);
          setCanAccessApp(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [hubConnection]);

  window.addEventListener('beforeunload', (event) => {
    // Your action code here
    localStorage.removeItem('cComName');
    localStorage.removeItem('cID_PnD');
    localStorage.removeItem('cEnvelop');
    localStorage.removeItem('cID_TnT');
    // For example, to display a confirmation message:
   // event.preventDefault();
  //  event.returnValue = 'Are you sure you want to leave?';
  });
  return (
    <li className="header-notification">
      <div className="dropdown-primary dropdown">
        <div className="dropdown-toggle" data-bs-toggle="dropdown">
          <i className="feather icon-layers" title="Products"></i>
        </div>
        <ul
          className="show-notification notification-view dropdown-menu"
          data-dropdown-in="fadeIn"
          data-dropdown-out="fadeOut"
        >
          <li style={{ textAlign: '-webkit-center' }}>
            <h6 style={{ fontSize: '20px' }}>TLS Apps</h6>
          </li>
          <li style={{ padding: '0', pointerEvents: 'none' }}>
            <hr style={{ padding: '0' }}></hr>
          </li>
          <li style={{ padding: '0' }}>
            <a style={{ padding: '1em 25px' }} href={window.location.origin + '/#/dashboard'}>
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <i className="feather icon-home img-radius" style={{ fontSize: '34px' }}></i>
                </div>
                <div className="flex-grow-1">
                  <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                    TLS's Dashboard
                  </h5>
                </div>
                <div className="flex-end" style={{ marginTop: '10px' }}>
                  <Tooltip content="Open in new window" idTool="mytooltip1">
                    <a href={window.location.origin + '/#/dashboard'} target="_blank">
                      <i className="fa fa-ellipsis-v"></i>
                    </a>
                  </Tooltip>
                </div>
              </div>
            </a>
          </li>
          {String(userData?.appAccess).length > 2 && (
            <li style={{ padding: '0', pointerEvents: 'none' }}>
              <hr style={{ padding: '0' }}></hr>
            </li>
          )}
          {String(userData?.appAccess).includes('reScale') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/reScale/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      className="img-radius"
                      src="./files/assets/images/reScalelogo_singleBlack.png"
                      alt="Generic placeholder "
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      reScale
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip2">
                      <a href={window.location.origin + '/reScale/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {String(userData?.appAccess).includes('reSort') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/reSort/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      className="img-radius"
                      src="./files/assets/images/logoreSort_singleBlack.png"
                      alt="Generic placeholder "
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      reSort
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip3">
                      <a href={window.location.origin + '/reSort/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {String(userData?.appAccess).includes('PND') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/PnD/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img className="img-radius" src="./files/assets/images/pnd_Icon.svg" alt="Generic placeholder " />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      reMail
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip3">
                      <a href={window.location.origin + '/PnD/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {String(userData?.appAccess).includes('TnT') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/TnT/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img className="img-radius" src="./files/assets/images/tntIcon.svg" alt="Generic placeholder " />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      reTrack
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip3">
                      {/* <NavLink
                                 href={window.location.origin + '/TnT/#/dashboard'}
                                  target="_blank"
                                > */}
                      <a href={window.location.origin + '/TnT/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {(canAccessApp || canAccessCustomer || canAccessUser) && (
            <li style={{ padding: '0', pointerEvents: 'none' }}>
              <hr style={{ padding: '0' }}></hr>
            </li>
          )}
          {canAccessApp && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/#/AppSetting'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="fa fa-server img-radius" style={{ fontSize: '34px' }}></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                      Applications Setting
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip4">
                      <a href={window.location.origin + '/#/AppSetting'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {canAccessCustomer && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/#/CustomerSetting'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="fa fa-suitcase img-radius" style={{ fontSize: '34px' }}></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                      Customers Setting
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip4">
                      <a href={window.location.origin + '/#/CustomerSetting'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {canAccessUser && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={window.location.origin + '/#/UserSetting'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="fa fa-users img-radius" style={{ fontSize: '34px' }}></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                      Users Setting
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip4">
                      <a href={window.location.origin + '/#/UserSetting'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default DropDownMenu;
