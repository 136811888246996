import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { publicRoutes } from './routes/routes';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { StoreProvider, handles } from './store';
import config from './config';
import GlobalStyles from './component/GlobalStyles';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('pcoded'));

const { isLoggedIn, getCookie } = handles;
root.render(
  <GlobalStyles>
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <Router>
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  isLoggedIn() ? (
                    <Navigate to={getCookie('lastVisitedPath') || config.routes.dashboard} />
                  ) : (
                    <route.layout>
                      <route.component />
                    </route.layout>
                  )
                }
              />
            ))}

            <Route path="*" element={<App />} />
          </Routes>
        </Router>
      </StoreProvider>
    </QueryClientProvider>
  </GlobalStyles>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
