import Cookies from 'js-cookie';
import axios from 'axios';
import { setSecureItem ,getSecureItem } from "../component/SecureStorage/secureStorage";
export const isLoggedIn = () => {
  return !!Cookies.get('loggedInUser');
};

export const getCookie = (nameCookie) => {
  return Cookies.get(nameCookie);
};
export const removeCookie = (nameCookie) => {
  return Cookies.remove(nameCookie);
};

export const setCookie = (nameCookie, valueCookie) => {
  const setCookie = Cookies.set(nameCookie, valueCookie);
  return setCookie;
};
export const fetchAPIGet2 = async (urlAPI, dataBody) => {
  try {
    const response = await fetch(`${getSecureItem('hubHost')}api/${urlAPI}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataBody),
    });
 
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
 
    const data = await response.json();
 
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};
export const fetchAPIGet = async (urlAPI) => {
  try {
    const response = await axios.get(`${getSecureItem('hubHost')}api/${urlAPI}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('API error response:', error.response.data);
      return error.response.data.message || 'An error occurred';
    } else if (error.request) {
      console.error('API error request:', error.request);
      return 'No response from server';
    } else {
      console.error('API error message:', error.message);
      return error.message;
    }
  }
};

export const fetchAPIPost = async (urlAPI, data, queryClient, arrayToCheck = []) => {
  try {
    const response = await axios.post(`${getSecureItem('hubHost')}api/${urlAPI}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (arrayToCheck.length > 0 && Array.isArray(arrayToCheck)) {
      queryClient.invalidateQueries(arrayToCheck);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('API error response:', error.response.data);
      return error.response.data.message || 'An error occurred';
    } else if (error.request) {
      console.error('API error request:', error.request);
      return 'No response from server';
    } else {
      console.error('API error message:', error.message);
      return error.message;
    }
  }
};

export const fetchAPIPut = async (urlAPI, data, queryClient, arrayToCheck) => {
  try {
    const response = await axios.put(`${getSecureItem('hubHost')}api/${urlAPI}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (arrayToCheck.length > 0 && Array.isArray(arrayToCheck)) {
      queryClient.invalidateQueries([arrayToCheck]);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('API error response:', error.response.data);
      return error.response.data.message || 'An error occurred';
    } else if (error.request) {
      console.error('API error request:', error.request);
      return 'No response from server';
    } else {
      console.error('API error message:', error.message);
      return error.message;
    }
  }
};

export const fetchAPIDelete = async (urlAPI, queryClient, arrayToCheck = []) => {
  try {
    const response = await axios.delete(`${getSecureItem('hubHost')}api/${urlAPI}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (arrayToCheck.length > 0 && Array.isArray(arrayToCheck)) {
      queryClient.invalidateQueries(arrayToCheck);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('API error response:', error.response.data);
      return error.response.data.message || 'An error occurred';
    } else if (error.request) {
      console.error('API error request:', error.request);
      return 'No response from server';
    } else {
      console.error('API error message:', error.message);
      return error.message;
    }
  }
};

export const runExistingScripts = (dispatch, toggleCheck, isCheck) => {
  const scripts = document.querySelectorAll('script[data-existing-script]');
  scripts.forEach((script) => {
    if (script) {
      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.src = script.src;
      document.head.appendChild(newScript);
    }
  });

  const scripts1 = document.querySelectorAll('script[data-script]');
  scripts1.forEach((script) => {
    if (script) {
      if (!isCheck) {
        dispatch(toggleCheck(true));
      } else {
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = script.src;
        document.head.appendChild(newScript);
      }
    }
  });
};

export const getDate = (millisecond) => {
  const timestamp = millisecond;
  const date = new Date(timestamp);
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};

export const convertToMilliseconds = (value, unit) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  if (unit === 'Hours') {
    const milliseconds = value * 60 * 60 * 1000;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Days') {
    const milliseconds = value * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Weeks') {
    const milliseconds = value * 7 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Months') {
    const milliseconds = value * 30 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  }

  return 0;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} , ${hours}:${minutes}`;
};

const createData = (timesDb, type) => {
  const data = [];
  let end, start;

  const config = {
    OneDay: {
      adjustStart: (start) => start.setHours(end.getHours() - 24),
      interval: 1000 * 60 * 60,
    },
    OneWeek: {
      adjustStart: (start) => start.setHours(end.getHours() - 24 * 7),
      interval: 1000 * 60 * 60,
    },
    OneMonth: {
      adjustStart: (start) => start.setDate(end.getDate() - 30),
      interval: 1000 * 60 * 60 * 24,
    },
    OneYear: {
      adjustStart: (start) => start.setFullYear(end.getFullYear() - 1),
      interval: 1000 * 60 * 60 * 24,
    },
  };

  const { adjustStart, interval } = config[type];
  if (!adjustStart) {
    throw new Error('Invalid type');
  }

  end = new Date();
  start = new Date(end);
  adjustStart(start);

  const timeDiff = end.getTime() - start.getTime();
  const steps = Math.floor(timeDiff / interval);

  for (let i = 0; i <= steps; i++) {
    const current = new Date(start.getTime() + i * interval);

    if (type === 'OneWeek' && ![0, 12].includes(current.getHours())) {
      continue;
    }

    const formattedDate = `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}-${String(
      current.getDate(),
    ).padStart(2, '0')}${
      ['OneDay', 'OneWeek'].includes(type) ? ` ${String(current.getHours()).padStart(2, '0')}:00` : ''
    }`;
    data.push({ date: formattedDate, market1: 0, market2: 0, market3: 0, market4: 0, market5: 0, market6: 0 });
  }

  //market1
  const timesDbAll = timesDb.map((j) => j.startTimestamp);
  timesDbAll?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market1++;
      }
    }
  });

  //market2
  const timesDbAllFailed = timesDb.filter((j) => j.status === 'Failed').map((j) => j.startTimestamp);
  timesDbAllFailed?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market2++;
      }
    }
  });

  //market3
  const timesDbOnDemand = timesDb.filter((j) => j.workflowGroup === 'ONLINE').map((j) => j.startTimestamp);
  timesDbOnDemand?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market3++;
      }
    }
  });

  //market4
  const timesDbOnDemandFailed = timesDb
    .filter((j) => j.workflowGroup === 'ONLINE' && j.status === 'Failed')
    .map((j) => j.startTimestamp);
  timesDbOnDemandFailed?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market4++;
      }
    }
  });

  //market5
  const timesDbBatch = timesDb.filter((j) => j.workflowGroup === 'BATCH').map((j) => j.startTimestamp);
  timesDbBatch?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market5++;
      }
    }
  });

  //market6
  const timesDbBATCHFailed = timesDb
    .filter((j) => j.workflowGroup === 'BATCH' && j.status === 'Failed')
    .map((j) => j.startTimestamp);
  timesDbBATCHFailed?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market6++;
      }
    }
  });
  return data;
};

export const ChartDashBoardAll = (dataFecth) => {
  const dataChart = [...dataFecth];
  window.AmCharts.makeChart('deal-analytic-chart', {
    type: 'serial',
    theme: 'light',
    precision: 0,
    valueAxes: [
      {
        id: 'v1',
        position: 'left',
        autoGridCount: false,
        labelFunction: function (value) {
          return '$' + Math.round(value) + 'M';
        },
      },
      {
        id: 'v2',
        gridAlpha: 0,
        autoGridCount: false,
      },
    ],
    graphs: [
      {
        id: 'g1',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#0d6efd',
        type: 'smoothedLine',
        title: 'All',
        useLineColorForBulletBorder: true,
        valueField: 'market1',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
      },
      {
        id: 'g2',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#dc3545',
        type: 'smoothedLine',
        title: 'Failed',
        useLineColorForBulletBorder: true,
        valueField: 'market2',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },

      {
        id: 'g3',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#198754',
        type: 'smoothedLine',
        title: 'On Demand All',
        useLineColorForBulletBorder: true,
        valueField: 'market3',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g4',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#fd7e14',
        type: 'smoothedLine',
        title: 'On Demand Failed',
        useLineColorForBulletBorder: true,
        valueField: 'market4',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g5',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#ffc107',
        type: 'smoothedLine',
        title: 'Batch All',
        useLineColorForBulletBorder: true,
        valueField: 'market5',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g6',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#6610f2',
        type: 'smoothedLine',
        title: 'Batch Failed',
        useLineColorForBulletBorder: true,
        valueField: 'market6',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
    ],
    chartCursor: {
      pan: true,
      valueLineEnabled: true,
      valueLineBalloonEnabled: true,
      cursorAlpha: 0,
      valueLineAlpha: 0.2,
    },
    chartScrollbar: {
      autoGridCount: true,
      graph: 'g1',
      oppositeAxis: true,
      scrollbarHeight: 40,
    },
    categoryField: 'date',
    categoryAxis: {
      parseDates: true,
      minPeriod: 'hh',
      dashLength: 1,
      minorGridEnabled: true,
      dateFormats: [
        { period: 'fff', format: 'JJ:NN:SS' },
        { period: 'ss', format: 'JJ:NN:SS' },
        { period: 'mm', format: 'JJ:NN' },
        { period: 'hh', format: 'JJ:NN' },
        { period: 'DD', format: 'MMM DD' },
        { period: 'WW', format: 'MMM DD' },
        { period: 'MM', format: 'MMM' },
        { period: 'YYYY', format: 'YYYY' },
      ],
    },
    legend: {
      useGraphSettings: true,
      position: 'top',
    },
    balloon: {
      borderThickness: 1,
      shadowAlpha: 0,
    },
    dataProvider: dataChart,
  });
};

export const ChartPieLatestBatch = (dataFecth) => {
  let dataTrans = [];
  dataTrans.push({ type: 'Foreign', value: dataFecth[0].sum_foreignsheet });
  dataTrans.push({ type: 'Normal zone (Domestic)', value: dataFecth[0].sum_normalzonesheet });
  dataTrans.push({ type: 'Low zone (Domestic)', value: dataFecth[0].sum_lowZonesheet });
  window.AmCharts.makeChart('allocation-chart', {
    type: 'pie',
    startDuration: 0,
    theme: 'light',
    labelRadius: 0,
    pullOutRadius: 0,
    labelText: '[[country]]',
    colorField: 'color',
    legend: {
      enabled: true,
      position: 'left',
    },
    innerRadius: '30%',
    dataProvider: dataTrans,
    // dataProvider: [{
    //   country: "Lithuania",
    //   litres: 501.9,
    //   color: "#85C5E3"
    // }, {
    //   country: "Czech Republic",
    //   litres: 301.9,
    //   color: "#6AA3C4"
    // }, {
    //   country: "Ireland",
    //   litres: 201.1,
    //   color: "#6097B9"
    // }, {
    //   country: "india",
    //   litres: 220.1,
    //   color: "#4E81A4"
    // }],
    valueField: 'value',
    titleField: 'type',
  });
};
export const CheckInputType = (input, dataCheck, edit = null, choiceCus = null) => {
  if (input) {
    if (edit === 'edit' && choiceCus) {
      const name = dataCheck.filter((data) => data.companyName === choiceCus);

      return dataCheck
        .filter((d) => d.companyName !== name.companyName)
        .every((data) => data.companyName.toUpperCase() !== input.toUpperCase());
    } else {
      return dataCheck.every((data) => data.companyName.toUpperCase() !== input.toUpperCase());
    }
  } else {
    return true;
  }
};
export const CheckInputCharacter = (input) => {
  const regex = /^[a-zA-Z1-9.\-_]*$/;
  if (regex.test(input)) {
    return true;
  } else {
    return false;
  }
};
