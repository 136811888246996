import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { handles } from '../../store';
import _ from 'lodash';
import { Tablist, ContainerModal } from '../../component';
import { RenderIf } from '../../utilities';
const ModalCustomerEdit = ({
  idModal,
  title = 'Title Modal',
  customerSelected,
  allowedApp,
  dataCustomer,
  setCustomerSelected,
}) => {
  //store
  const { fetchAPIPut, CheckInputCharacter } = handles;
  //useQueryClient
  const queryClient = useQueryClient();
  //
  const [customerModal, setCustomerModal] = useState(null);
  const retention = Array.from({ length: 30 }, (_, index) => index + 1);
  const [disabledModal, setDisabledModal] = useState(true);
  const typeRetention = ['Day', 'Week', 'Month'];
  // check
  const [isShowRetention, setIsShowRetention] = useState(false);
  const [isShowTypeRetention, setIsShowTypeRetention] = useState(false);
  const [isShowRetentionTnT, setIsShowRetentionTnT] = useState(false);
  const [isShowTypeRetentionTnT, setIsShowTypeRetentionTnT] = useState(false);
  const [numberUserHave, setNumberUserHave] = useState(0);
  const [numberUserHaveTnT, setNumberUserHaveTnT] = useState(0);
  const [envolopCheck, setEnvolopCheck] = useState([]);
  const [disabledCustom, setdisabledCustom] = useState([]);
  // useREf
  const customerModalPrevRef = useRef(null);

  //useEffect
  useEffect(() => {
    if (customerSelected) {
      const updatedCustomer = {
        ...customerSelected,
        allowedApps: JSON.parse(customerSelected?.allowedApps || '{}'),
      };
      setDisabledModal(true);
      customerModalPrevRef.current = updatedCustomer;
      setCustomerModal(updatedCustomer);
      // setdisabledCustom(['duplicate', 'envelopes', 'character', 'name']);
      setdisabledCustom([]);
    }
  }, [customerSelected]);

  useEffect(() => {
    const pndNumberUser = dataCustomer?.reduce((total, customer) => {
      const data = JSON.parse(customer.allowedApps);
      const hasPND = data.PND?.status;
      if (hasPND && customer.id !== customerSelected.id) {
        return total + (+data.PND.description?.allowedUsers || 0);
      }
      return total;
    }, 0);
    setNumberUserHave(pndNumberUser);
    // eslint-disable-next-line
  }, [dataCustomer, customerSelected]);
  useEffect(() => {
    const tnTNumberUser = dataCustomer?.reduce((total, customer) => {
      const data = JSON.parse(customer.allowedApps);
      const hasTnT = data.TnT?.status;
      if (hasTnT && customer.id !== customerSelected.id) {
        return total + (+data.TnT.description?.allowedUsers || 0);
      }
      return total;
    }, 0);
    setNumberUserHaveTnT(tnTNumberUser);
    // eslint-disable-next-line
  }, [dataCustomer, customerSelected]);

  const handleCheckApp = (e) => {
    if (e.target.name) {
      const updatedCustomer = updateAllowedAppsStatus(e.target.name, e.target.checked);
      updateCustomer(updatedCustomer);
    }
  };

  const updateAllowedAppsStatus = (appName, isChecked) => {
    let updatedCustomer = { ...customerModal };

    if (updatedCustomer.allowedApps && updatedCustomer.allowedApps[appName]) {
      let objectData;
      const description = {
        allowedUsers: 0,
        envelopes: [],
        overdate: ['Day', 1],
      };
      const descriptionTnT = {
        allowedUsers: 0,
        overdate: ['Day', 1],
      };
      if (appName === 'PND') {
        objectData = {
          ...(isChecked ? { ...updatedCustomer.allowedApps[appName] } : { description: description }),
          status: isChecked,
        };
      } else if (appName === 'TnT') {
        objectData = {
          ...(isChecked ? { ...updatedCustomer.allowedApps[appName] } : { description: descriptionTnT }),
          status: isChecked,
        };
      } else {
        objectData = {
          status: isChecked,
        };
      }

      updatedCustomer.allowedApps = {
        ...updatedCustomer.allowedApps,
        [appName]: objectData,
      };
    }

    return updatedCustomer;
  };
  const handleUpdatePNDConfig = (e) => {
    const { value, dataset } = e.currentTarget;

    let valueUpdateOverdate = null;
    if (dataset.name === 'overdate') {
      valueUpdateOverdate = [...customerModal.allowedApps.PND.description.overdate];
      valueUpdateOverdate[dataset.location] = isNaN(dataset.value) ? dataset.value.trim() : +dataset.value;
    }

    if (dataset.name === 'envelopes') {
      if (dataset.handle === 'post') {
        valueUpdateOverdate = [...customerModal.allowedApps.PND.description.envelopes, dataset.value];
      } else if (dataset.handle === 'delete') {
        valueUpdateOverdate = customerModal.allowedApps.PND.description.envelopes.filter(
          (_, index) => index !== +dataset.index,
        );
      } else if (dataset.handle === 'put') {
        valueUpdateOverdate = customerModal.allowedApps.PND.description.envelopes.map((data, index) => {
          if (index === +dataset.index) {
            return value.trim();
          }
          return data;
        });
      }
      if (valueUpdateOverdate.length > 0) {
        const hasDuplicates = new Set(valueUpdateOverdate).size !== valueUpdateOverdate.length;
        setEnvolopCheck((prev) =>
          hasDuplicates ? [...prev, +dataset.index] : prev.filter((d) => d !== +dataset.index),
        );
      }
    }

    let valueUpdate =
      dataset.type === 'number' ? parseInt(valueUpdateOverdate || value, 10) : valueUpdateOverdate || value.trim();
    if (dataset.name === 'allowedUsers') {
      const limit = +allowedApp[0].allowedUsers - numberUserHave;
      const valueCheck = valueUpdate;
      if (valueCheck && !isNaN(valueCheck)) {
        const numValue = parseInt(valueCheck, 10);
        if (numValue > limit) {
          valueUpdate = limit;
        }
      }
    }
    const updatedCustomer = {
      ...customerModal,
      allowedApps: {
        ...customerModal.allowedApps,
        PND: {
          ...customerModal.allowedApps.PND,
          description: {
            ...customerModal.allowedApps.PND.description,
            [dataset.name]: valueUpdate,
          },
        },
      },
    };
    updateCustomer(updatedCustomer);
  };
  const handleUpdateTnTConfig = (e) => {
    const { value, dataset } = e.currentTarget;

    let valueUpdateOverdate = null;
    if (dataset.name === 'overdate') {
      valueUpdateOverdate = [...customerModal.allowedApps.TnT.description.overdate];
      valueUpdateOverdate[dataset.location] = isNaN(dataset.value) ? dataset.value.trim() : +dataset.value;
    }

    let valueUpdate =
      dataset.type === 'number' ? parseInt(valueUpdateOverdate || value, 10) : valueUpdateOverdate || value.trim();
    if (dataset.name === 'allowedUsers') {
      const limit = +allowedApp[0].allowedUsers - numberUserHave;
      const valueCheck = valueUpdate;
      if (valueCheck && !isNaN(valueCheck)) {
        const numValue = parseInt(valueCheck, 10);
        if (numValue > limit) {
          valueUpdate = limit;
        }
      }
    }
    const updatedCustomer = {
      ...customerModal,
      allowedApps: {
        ...customerModal.allowedApps,
        TnT: {
          ...customerModal.allowedApps.TnT,
          description: {
            ...customerModal.allowedApps.TnT.description,
            [dataset.name]: valueUpdate,
          },
        },
      },
    };
    updateCustomer(updatedCustomer);
  };

  const updateCustomer = (updatedCustomer) => {
    setCustomerModal(updatedCustomer);
    updateDisabledModal(updatedCustomer, customerModalPrevRef.current);
  };

  const updateDisabledModal = (dataNew, dataPrev) => {
    const valueUpdateOverdate = [...dataNew.allowedApps.PND.description.envelopes];
    const hasInvalidElements = valueUpdateOverdate.some((item) => item === null || item === undefined || item === '');
    const hasDuplicates = new Set(valueUpdateOverdate).size !== valueUpdateOverdate.length;
    const isSame = !dataNew?.companyName || hasInvalidElements || hasDuplicates || _.isEqual(dataNew, dataPrev);
    setDisabledModal(isSame);
  };

  const handleSave = () => {
    const customerToUpdate = { ...customerModal };
    if (customerToUpdate.allowedApps.PND.status === true) {
      customerToUpdate.allowedApps.PND = {
        ...customerToUpdate.allowedApps.PND,
        description: { ...customerToUpdate.allowedApps.PND.description },
      };
    }
    if (customerToUpdate.allowedApps.TnT.status === true) {
      customerToUpdate.allowedApps.TnT = {
        ...customerToUpdate.allowedApps.TnT,
        description: { ...customerToUpdate.allowedApps.TnT.description },
      };
    }
    customerToUpdate.allowedApps = JSON.stringify(customerModal.allowedApps);

    fetchAPIPut('Company', customerToUpdate, queryClient, ['companies', 'quantityStatistics']);
  };
  const handleCheckInput = (isHandle, type) => {
    if (isHandle === 'post' && !disabledCustom.includes(type)) {
      setdisabledCustom((prev) => [...prev, type]);
    } else if (isHandle === 'delete' && disabledCustom.includes(type)) {
      setdisabledCustom((prev) => prev.filter((d) => d !== type));
    }
  };
  const handleCheckName = (e) => {
    const { value } = e.currentTarget;
    const updatedCustomer = { ...customerModal, companyName: value.trim() };
    setCustomerModal(updatedCustomer);
    updateDisabledModal(updatedCustomer, customerModalPrevRef.current);
    const check = demo(e.target.value.trim(), dataCustomer, customerSelected.companyName);
    ['name', 'character', 'duplicate'].forEach((value) =>
      handleCheckInput(check.includes(value) ? 'post' : 'delete', value),
    );
  };

  const demo = (value, arrayCheck, valueDefault) => {
    const arrCheck = [];
    if (!value) arrCheck.push('name');
    if (!CheckInputCharacter(value)) arrCheck.push('character');
    if (
      arrayCheck
        .filter((arr) => arr.companyName !== valueDefault)
        .some((arr) => arr.companyName.toUpperCase() === value.toUpperCase())
    )
      arrCheck.push('duplicate');
    return arrCheck;
  };
  const renderArrayValue = (dataArray, location) => {
    if (dataArray.length > 0) return dataArray[location];
    return 0;
  };
  const handleCancel = () => {
    setCustomerSelected({});
  };

  return (
    <ContainerModal
      idModal={idModal}
      title={title}
      listButton={[
        { name: 'CANCEL', isHandle: handleCancel },
        {
          name: 'SAVE',
          colorButton: 'btn-primary',
          isHandle: handleSave,
          disabled:
            disabledCustom.includes('name') ||
            disabledCustom.includes('duplicate') ||
            disabledCustom.includes('character') ||
            disabledModal,
        },
      ]}
      isHandleClose={handleCancel}
    >
      <Tablist
        listTab={[
          { name: 'Information', linkTab: 'infor' },
          { name: 'reMail', linkTab: 'PnD', isDisabled: !customerModal?.allowedApps?.PND?.status },
          { name: 'reTrack', linkTab: 'TnTEd', isDisabled: !customerModal?.allowedApps?.TnT?.status },
        ]}
        dependency={customerSelected}
        parentId={idModal}
      />
      <div className="tab-content tabs card-block">
        {/* information */}
        <div className="tab-pane active" id="infor" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="infor" role="tabpanel">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Name</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleCheckName}
                    value={customerModal?.companyName || ''}
                  />
                  {disabledCustom?.includes('name') && (
                    <span style={{ color: 'red', marginLeft: '0' }}>Name can't be blank !!!</span>
                  )}
                  {disabledCustom?.includes('duplicate') && (
                    <span style={{ color: 'red', marginLeft: '0' }}>
                      Can't use this name! Please use another name !!!
                    </span>
                  )}
                  {disabledCustom?.includes('character') && (
                    <span style={{ color: 'red', marginLeft: '0' }}>
                      Only the character "." "-" "_" can be used. !!!
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">App Access</label>
                </div>
                <div className="col">
                  {Object.entries(customerModal?.allowedApps || {}).map(([appName, appData]) => (
                    <div className="row" key={appName}>
                      <div className="col-sm-3 ds-flex">
                        <input
                          name={appName}
                          id={appName}
                          type="checkbox"
                          checked={appData?.status}
                          onChange={(e) => handleCheckApp(e)}
                        />
                        <label className="col-form-label">
                          {appName === 'PND' ? 'reMail' : appName === 'TnT' ? 'reTrack' : appName}{' '}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`tab-pane ${!customerModal?.allowedApps?.PND?.status && 'hidden'}`} id="PnD" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="PnD" role="tabpanel">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Users</label>
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    data-name="allowedUsers"
                    data-type="number"
                    min={0}
                    onChange={handleUpdatePNDConfig}
                    value={customerModal?.allowedApps?.PND?.description?.allowedUsers || 0}
                  />
                  {/* <span>
                    Max user is <strong>{allowedApp?.[0]?.allowedUsers - numberUserHave}</strong>
                  </span> */}
                  <span>
                    Max user is{' '}
                    <strong>
                      {allowedApp?.map((data) => data.applicationName === 'PND' && data.allowedUsers - numberUserHave)}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Retention</label>
                </div>
                <div className="col-sm-3">
                  <div className="btn-dropdown" onClick={() => setIsShowRetention(!isShowRetention)}>
                    {renderArrayValue(customerModal?.allowedApps?.PND?.description?.overdate || [], 1)}
                    {!isShowRetention ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </div>
                  {isShowRetention && (
                    <div className="body-dropdown">
                      {retention.map((data) => (
                        <div
                          className="active-dropdown"
                          data-name="overdate"
                          data-value={data}
                          data-location={1}
                          onClick={(e) => {
                            handleUpdatePNDConfig(e);
                            setIsShowRetention(!isShowRetention);
                          }}
                          key={data}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col">
                  <div className="btn-dropdown" onClick={() => setIsShowTypeRetention(!isShowTypeRetention)}>
                    {renderArrayValue(customerModal?.allowedApps?.PND?.description?.overdate || [], 0)}
                    {!isShowTypeRetention ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </div>
                  {isShowTypeRetention && (
                    <div className="body-dropdown" style={{ width: 'inherit !important' }}>
                      {typeRetention.map((data) => (
                        <div
                          className="active-dropdown"
                          data-name="overdate"
                          data-value={data}
                          data-location={0}
                          onClick={(e) => {
                            handleUpdatePNDConfig(e);
                            setIsShowTypeRetention(!isShowTypeRetention);
                          }}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Envelope</label>
                </div>
                <RenderIf isTrue={(customerModal?.allowedApps?.PND?.description?.envelopes || []).length !== 0}>
                  <div className="col-sm-9">
                    {(customerModal?.allowedApps?.PND?.description?.envelopes || []).map((data, index) => (
                      <>
                        <div key={index} style={{ display: 'flex', marginBottom: '15px' }}>
                          <input
                            type="text"
                            className="form-control"
                            data-name="envelopes"
                            data-index={index}
                            data-handle="put"
                            onChange={handleUpdatePNDConfig}
                            value={data}
                          />

                          <button
                            className="btn"
                            style={{ marginLeft: '10px' }}
                            data-name="envelopes"
                            data-value={data}
                            data-index={index}
                            data-handle="delete"
                            onClick={handleUpdatePNDConfig}
                          >
                            <i id={data} className="icofont icofont-trash"></i>
                          </button>
                        </div>
                        {envolopCheck.includes(index) && (
                          <span style={{ color: 'red' }}>Envelope already exists, use another one</span>
                        )}
                      </>
                    ))}
                  </div>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-9">
                    <button
                      className="btn"
                      data-name="envelopes"
                      data-value=""
                      data-handle="post"
                      onClick={handleUpdatePNDConfig}
                    >
                      <i className="icofont icofont-plus" style={{ color: 'green' }}></i>
                      Add more envelope
                    </button>
                  </div>
                </RenderIf>
                <RenderIf isTrue={(customerModal?.allowedApps?.PND?.description?.envelopes || []).length === 0}>
                  <div className="col-sm-9">
                    <button
                      className="btn"
                      data-name="envelopes"
                      data-value=""
                      data-handle="post"
                      onClick={handleUpdatePNDConfig}
                    >
                      <i className="icofont icofont-plus" style={{ color: 'green' }}></i>
                      Add more envolop
                    </button>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
        <div className={`tab-pane ${!customerModal?.allowedApps?.TnT?.status && 'hidden'}`} id="TnTEd" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="TnTEd" role="tabpanel">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Users</label>
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    data-name="allowedUsers"
                    data-type="number"
                    min={0}
                    onChange={handleUpdateTnTConfig}
                    value={customerModal?.allowedApps?.TnT?.description?.allowedUsers || 0}
                  />
                  {/* <span>
                    Max user is <strong>{allowedApp?.[0]?.allowedUsers - numberUserHave}</strong>
                  </span> */}
                  <span>
                    Max user is{' '}
                    <strong>
                      {allowedApp?.map(
                        (data) => data.applicationName === 'TnT' && data.allowedUsers - numberUserHaveTnT,
                      )}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Retention</label>
                </div>
                <div className="col-sm-3">
                  <div className="btn-dropdown" onClick={() => setIsShowRetentionTnT(!isShowRetentionTnT)}>
                    {renderArrayValue(customerModal?.allowedApps?.TnT?.description?.overdate || [], 1)}
                    {!isShowRetentionTnT ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </div>
                  {isShowRetentionTnT && (
                    <div className="body-dropdown">
                      {retention.map((data) => (
                        <div
                          className="active-dropdown"
                          data-name="overdate"
                          data-value={data}
                          data-location={1}
                          onClick={(e) => {
                            handleUpdateTnTConfig(e);
                            setIsShowRetentionTnT(!isShowRetentionTnT);
                          }}
                          key={data}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col">
                  <div className="btn-dropdown" onClick={() => setIsShowTypeRetentionTnT(!isShowTypeRetentionTnT)}>
                    {renderArrayValue(customerModal?.allowedApps?.TnT?.description?.overdate || [], 0)}
                    {!isShowTypeRetentionTnT ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </div>
                  {isShowTypeRetentionTnT && (
                    <div className="body-dropdown" style={{ width: 'inherit !important' }}>
                      {typeRetention.map((data) => (
                        <div
                          className="active-dropdown"
                          data-name="overdate"
                          data-value={data}
                          data-location={0}
                          onClick={(e) => {
                            handleUpdateTnTConfig(e);
                            setIsShowTypeRetentionTnT(!isShowTypeRetentionTnT);
                          }}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerModal>
  );
};

export default ModalCustomerEdit;
