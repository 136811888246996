import ReactDOM from 'react-dom';
const ContainerModal = ({ children, idModal, title, isHandleClose = null, listButton = [{ name: 'CANCEL' }] }) => {
  return ReactDOM.createPortal(
    <div className="modal fade" id={idModal} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center">{title}</h4>
            <span id="success-edit-message" className="messages"></span>
            <button
              id="modalboxUpdate"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={isHandleClose ? isHandleClose : undefined}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            {listButton.map((item, index) => (
              <button
                key={index}
                type="button"
                className={`btn ${(item.disabled && 'btn-disable') || item.colorButton || 'btn-default'} waves-effect `}
                onClick={item.isHandle ? item.isHandle : undefined}
                data-bs-dismiss="modal"
                disabled={item.disabled}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default ContainerModal;
