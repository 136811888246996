import { useState, useEffect, useLayoutEffect } from 'react';
import { useStore, actions, handles } from '../../store';
import Cookies from 'js-cookie';
import Widget from './PnDWidget';
import WidgetTnT from './TnTWidget';
import { setSecureItem, getSecureItem } from '../../component/SecureStorage/secureStorage';
import ReScaleWidget from './ReScaleWidget';
function Default() {
  //store
  const { state, dispatch } = useStore();
  const { hubConnection, isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { ChartPieLatestBatch, getCookie, runExistingScripts, getDate } = handles;
  //useRef

  const userLogin = JSON.parse(getCookie('loggedInUser'));
  const [dataLast24h, setDataLast24h] = useState([]);
  const [loading] = useState(false);
  const [, setReSortBatchAll] = useState([]);
  const [, setReSortBatchMonth] = useState();
  const [, setReSortBatchDay] = useState();
  const [openTicket, setOpenTicket] = useState(null);
  const [closeTicket, setCloseTicket] = useState(null);
  const [dataLicense, setDataLicense] = useState();
  const [countDocument, setCountDocument] = useState([]);
  const [countSheet, setCountSheet] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [dataBatchInMonth, setDataBatchInMonth] = useState();
  const [, setDataBatchStat] = useState([]);
  const [userData, setuserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  // company
  const [company, setCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(getSecureItem('cID'));
  const [companyTnT, setCompanyTnT] = useState([]);
  const [selectedCompanyTnT, setSelectedCompanyTnT] = useState(getSecureItem('cID_TnT2'));
  const [timeReload, setTimeReload] = useState(0);
  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hubConnection) {
      //invoke
      let dataUserCheck;
      let loginUser;
      // user
      hubConnection.invoke('ReadUsers').catch((err) => console.log(err));
      //on
      hubConnection.on('SendReadUsers', (users) => {
        const dataUser = users.filter((data) => data.user === userLogin.user);
        if (userLogin.user === 'ADMIN' || JSON.parse(dataUser?.permission).includes('admin')) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        dataUserCheck = users;
        loginUser = dataUser;
      });
      //invoke
      hubConnection.invoke('ReadJobsDashboard').catch((err) => console.log(err));
      //
      hubConnection.on('SendReadJobsDashboard', (param) => {
        setDataLast24h(param);
      });
      // ticket
      hubConnection.invoke('ReadTicketJobs').catch((err) => console.log(err));
      hubConnection.on('SendReadTicketJobs', (param) => {
        let user;

        dataUserCheck.forEach((data) => {
          if (data.user === userLogin.user) {
            user = data;
          }
        });

        let dataCheck = [];
        param.forEach((data) => {
          let userAsignee = data.ticketAssignee.split(',');
          let permissionUser = JSON.parse(user.permission);
          if (permissionUser.includes('admin')) {
            dataCheck.push(data);
          } else if (userAsignee.includes(userLogin.user)) {
            dataCheck.push(data);
          }
        });
        //
        let dataContOpen = 0;
        let dataCountClose = 0;
        dataCheck.forEach((data) => {
          if (data.ticketStatus === 3) {
            dataCountClose += 1;
          } else {
            dataContOpen += 1;
          }
        });

        setCloseTicket(dataCountClose);
        setOpenTicket(dataContOpen);
      });
      // current user info
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));

      hubConnection.on('SendReadEmailUserByUserName', (param) => {
        setuserData(param);
      });
    }
   

  }, [hubConnection]);
  // onChange Connect

  //useEffect
  useEffect(() => {
    const reNewData = async () => {
      if (hubConnection) {
        fetch(getSecureItem('reSortApiUrl') + '/api/LatestBatchnStatistics')
          .then((response) => response.json())
          .then((data) => {
            setDataBatchStat(data);
            ChartPieLatestBatch(data);
          })
          .catch((error) => console.error('Error fetching data:', error));
      }
      fetch(getSecureItem('reSortApiUrl') + '/api/distributorsstatistics')
      .then((response) => response.json())
      .then((data) => setDataAll(data))
      .catch((error) => console.error('Error fetching data:', error));
    // Fetch data from API
    fetch(getSecureItem('reSortApiUrl') + '/api/countbatchesinmonth')
      .then((response) => response.json())
      .then((data) => setDataBatchInMonth(data))
      .catch((error) => console.error('Error fetching data:', error));

    fetch(getSecureItem('reSortApiUrl') + '/api/LatestBatchnStatistics')
      .then((response) => response.json())
      .then((data) => {
        setDataBatchStat(data);
        ChartPieLatestBatch(data);
      })
      .catch((error) => console.error('Error fetching data:', error));

    fetch(getSecureItem('reSortApiUrl') + '/api/batch/count')
      .then((response) => response.json())
      .then((data) => setReSortBatchDay(data))
      .catch((error) => console.error('Error fetching data:', error));

    fetch(getSecureItem('reSortApiUrl') + '/api/countbatchesinmonth')
      .then((response) => response.json())
      .then((data) => setReSortBatchMonth(data))
      .catch((error) => console.error('Error fetching data:', error));

    fetch(getSecureItem('reSortApiUrl') + '/api/allbatch')
      .then((response) => response.json())
      .then((data) => setReSortBatchAll(data))
      .catch((error) => console.error('Error fetching data:', error));

    fetch(getSecureItem('reSortApiUrl') + '/api/countdocumentinMonth')
      .then((response) => response.json())
      .then((data) => setCountDocument(data))
      .catch((error) => console.error('Error fetching data:', error));
    fetch(getSecureItem('reSortApiUrl') + '/api/countsheetinMonth')
      .then((response) => response.json())
      .then((data) => setCountSheet(data))
      .catch((error) => console.error('Error fetching data:', error));
    // eslint-disable-next-line
    };
    reNewData();
    // eslint-disable-next-line
  }, [timeReload]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeReload(prev => (prev + 1) % 100);
    }, 10000); // 10 seconds = 10000 ms
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [])
  useEffect(() => {
    if (hubConnection) {
      hubConnection.invoke('ReadLicense').catch((err) => console.log('lic error: ' + err));
      hubConnection.on('SendReadLicense', (param) => {
        const dataLicenseKey = JSON.parse(param);
        setDataLicense(dataLicenseKey);
      });
      hubConnection.invoke('ReadCompanies').catch((err) => console.log(err));
      hubConnection.on('SendReadCompanies', (paramorg) => {
        let param = paramorg.filter((o) => o.allowedApps.includes('"PND":{"status":true,'));
        let paramTnT = paramorg.filter((o) => JSON.parse(o.allowedApps)['TnT'].status);
        // let paramTnT = paramorg.filter((o) => o.allowedApps.includes('"TnT":{"status":true}'));
        //admin internal
        if (userData.companyId === 0 && userData.userGroup === '[]') {
          setCompany(param);
          setSelectedCompany(param[0].id);


          setCompanyTnT(paramTnT);
          setSelectedCompanyTnT(paramTnT[0].companyName);
          setSecureItem('cID', param[0].id);
          setSecureItem('cID_TnT2', paramTnT[0].companyName);
        } else {
          if (userData.companyId !== 0) {
            const commonElements = param.filter((element) => element.id === userData.companyId);
            const commonElementsTnT = paramTnT.filter((element) => element.id === userData.companyId);
            setCompany(commonElements);
            setSelectedCompany(commonElements[0].id);
            setCompanyTnT(commonElementsTnT);

            setSelectedCompanyTnT(commonElementsTnT[0].companyName);
            setSecureItem('cID', commonElements[0].id);
            setSecureItem('cID_TnT2', commonElementsTnT[0].companyName);
          } else {
            let comGroup = JSON.parse(userData.userGroup);
            const commonElements = param.filter((element) => comGroup.includes(element.id));
            setCompany(commonElements);
            setSelectedCompany(commonElements[0].id);
            const commonElementsTnT = paramTnT.filter((element) => comGroup.includes(element.id));
            setCompanyTnT(commonElementsTnT);

            setSelectedCompanyTnT(commonElementsTnT[0].companyName);
            setSecureItem('cID', commonElements[0].id);
            setSecureItem('cID_TnT2', commonElementsTnT[0].companyName);
          }
        }
      });
    }
    return () => {
      if (hubConnection) {
        hubConnection.off('SendReadCompanies');
      }
    };
  }, [hubConnection, userData]);

  const handleValueDropDocument = (e) => {
    setSelectedCompany(e.target.value);
    setSecureItem('cID', e.target.value);
  };
  const handleValueDropDocumentTnT = (e) => {
    setSelectedCompanyTnT(e.target.value);
    setSecureItem('cID_TnT2', e.target.value);
  };

  return (
    <div className={`pcoded-content${loading ? ' card card-load' : ''}`}>
      <div className="page-header card">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <div className="page-header-title">
              <i className="feather icon-home bg-c-blue"></i>
              <div className="d-inline">
                <h5>Dashboard</h5>
              </div>
              {/* <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="page-header-breadcrumb">
              <ul className=" breadcrumb breadcrumb-title breadcrumb-padding">
                {/* <li className="breadcrumb-item">
                  <NavLink to="/dashboard">
                    <i className="feather icon-home"></i>
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <a href=""> </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-md-12 col-xl-8">
                  {/* <div className="card sale-card">
                    <div className="card-header">
                      <h5>Deals Analytics</nnnh5>
                    </div> */}
                  {/* new */}
                  {isAdmin && (
                    <div className="card sale-card">
                      <div className="card-header">
                        <img
                          src="./files/assets/images/logoTLSsingleblack.png"
                          style={{ width: '25px', marginRight: '5px' }}
                          alt="img"
                        />
                        <h5>APPLICATION LIST</h5>
                      </div>
                      <div className="card-block">
                        <div className="table-responsive">
                          <table className="table m-b-0  table-borderless">
                            <thead>
                              <tr>
                                <th>Application </th>
                                <th>License type</th>
                                <th>User</th>
                                <th>Expiration Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataLicense?.Applications.map((x, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="d-inline-block align-middle">
                                      <h6>
                                        {x.ApplicationName === 'PnD'
                                          ? 'reMail'
                                          : x.ApplicationName === 'TnT'
                                            ? 'reTrack'
                                            : x.ApplicationName}
                                      </h6>
                                      <p className="text-muted m-b-0"></p>
                                    </div>
                                  </td>
                                  <td>{x.Type}</td>
                                  <td>{x.User}</td>

                                  <td className="text-c-blue">{getDate(x.Valid)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {String(userData.appAccess).includes('reScale') && (
                <>
                  <div id="allreScale1" style={{ display: 'flex', alignItems: 'center' }}>
                    <b>reScale's Widgets</b>
                  </div>
                  <hr id="allreScale2" style={{ marginTop: '1px', backgroundColor: 'black', opacity: 1 }}></hr>
                </>
              )}

              {String(userData.appAccess).includes('reScale') && <ReScaleWidget />}
              {String(userData.appAccess).includes('reSort') && (
                <>
                  <div id="allreSort1" style={{ display: 'flex', alignItems: 'center' }}>
                    <b>reSort's Widgets</b>
                  </div>
                  <hr id="allreSort2" style={{ marginTop: '1px', backgroundColor: 'black', opacity: 1 }}></hr>
                </>
              )}
              {/* reSort widget*/}
              {String(userData.appAccess).includes('reSort') && (
                <div className="row">
                  <div className="col-md-12" id="wSort1">
                    <div className="card product-progress-card">
                      <div className="card-block">
                        <div className="row pp-main">
                          <div className="col-xl-4 col-md-6">
                            <div className="pp-cont">
                              <div className="row align-items-center m-b-20">
                                <div className="col-auto">
                                  <img
                                    src="./files/assets/images/logoreSort_singleBlack.png"
                                    style={{ width: '30px', marginRight: '5px' }}
                                    alt="img"
                                  />
                                </div>
                                <div className="col text-end">
                                  <h2 className="m-b-0 text-c-blue">
                                    {new Intl.NumberFormat().format(dataBatchInMonth?.count)}
                                  </h2>
                                </div>
                              </div>
                              <div className="row align-items-center m-b-15">
                                <div className="col-auto">
                                  <p className="m-b-0">Total jobs in the current month</p>
                                </div>
                                <div className="col text-end">
                                  {/* <p className="m-b-0 text-c-blue"><i className="fas fa-long-arrow-alt-up m-r-10"></i></p> */}
                                </div>
                              </div>
                              <div className="progress">
                                <div className="progress-bar bg-c-blue" style={{ width: '100%' }}></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="pp-cont">
                              <div className="row align-items-center m-b-20">
                                <div className="col-auto">
                                  <img
                                    src="./files/assets/images/logoreSort_singleBlack.png"
                                    style={{ width: '30px', marginRight: '5px' }}
                                    alt="img"
                                  />
                                </div>
                                <div className="col text-end">
                                  <h2 className="m-b-0 text-c-blue">
                                    {new Intl.NumberFormat().format(countDocument?.total_num_documents_in_month)}
                                  </h2>
                                </div>
                              </div>
                              <div className="row align-items-center m-b-15">
                                <div className="col-auto">
                                  <p className="m-b-0">Total documents in the current month</p>
                                </div>
                                <div className="col text-end">
                                  {/* <p className="m-b-0 text-c-red"><i className="fas fa-long-arrow-alt-up m-r-10"></i></p> */}
                                </div>
                              </div>
                              <div className="progress">
                                <div className="progress-bar bg-c-blue" style={{ width: '100%' }}></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="pp-cont">
                              <div className="row align-items-center m-b-20">
                                <div className="col-auto">
                                  <img
                                    src="./files/assets/images/logoreSort_singleBlack.png"
                                    style={{ width: '30px', marginRight: '5px' }}
                                    alt="img"
                                  />
                                </div>
                                <div className="col text-end">
                                  <h2 className="m-b-0 text-c-blue">
                                    {new Intl.NumberFormat().format(countSheet.sum)}
                                  </h2>
                                </div>
                              </div>
                              <div className="row align-items-center m-b-15">
                                <div className="col-auto">
                                  <p className="m-b-0">Total sheets in the current month</p>
                                </div>
                                <div className="col text-end">
                                  {/* <p className="m-b-0 text-c-yellow"><i className="fas fa-long-arrow-alt-up m-r-10"></i></p> */}
                                </div>
                              </div>
                              <div className="progress">
                                <div className="progress-bar bg-c-blue" style={{ width: '100%' }}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-4" id="wSort2">
                    <div className="card sale-card">
                      <div className="card-header">
                        <img
                          src="./files/assets/images/logoreSort_singleBlack.png"
                          style={{ width: '25px', marginRight: '5px' }}
                          alt="img"
                        />
                        <h5>Latest Jobs</h5>
                      </div>
                      <div className="col-xl-12 col-md-4">
                        <div id="allocation-chart" className="chart-shadow" style={{ height: '250px' }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-4" id="wSort3">
                    <div className="card o-hidden">
                      <div className="card-header">
                        <img
                          src="./files/assets/images/logoreSort_singleBlack.png"
                          style={{ width: '25px', marginRight: '5px' }}
                          alt="img"
                        />
                        <h5>PostNord </h5>
                      </div>
                      <div className="card-block">
                        {dataAll.length > 0 &&
                          dataAll?.map((x, index) => (
                            <div key={index} className="row">
                              <div className="col-4">
                                <p className="text-muted m-b-5">Total [sheet]</p>
                                <h6>
                                  {new Intl.NumberFormat().format(
                                    Number(x.NormalzonePostnord) +
                                    Number(x.SUM_Postnord_LowZone) +
                                    Number(x.SUM_Foreign),
                                  )}
                                </h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Domestic [sheet]</p>
                                <h6>
                                  {new Intl.NumberFormat().format(
                                    Number(x.NormalzonePostnord) + Number(x.SUM_Postnord_LowZone),
                                  )}
                                </h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Foreign [sheet]</p>
                                <h6>{new Intl.NumberFormat().format(x.SUM_Foreign)}</h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Normal zone [sheet]</p>
                                <h6>{new Intl.NumberFormat().format(x.NormalzonePostnord)}</h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Low zone [sheet]</p>
                                <h6>{new Intl.NumberFormat().format(x.SUM_Postnord_LowZone)}</h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Total price</p>
                                <h6>
                                  {new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' }).format(
                                    x.SUM_Postnord_Price,
                                  )}
                                </h6>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* <div id="sal-incomeReSort" style={{ height: "100px" }}></div> */}
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-4" id="wSort4">
                    <div className="card o-hidden">
                      <div className="card-header">
                        <img
                          src="./files/assets/images/logoreSort_singleBlack.png"
                          style={{ width: '25px', marginRight: '5px' }}
                          alt="img"
                        />
                        <h5>Bring</h5>
                      </div>
                      <div className="card-block">
                        {dataAll.length > 0 &&
                          dataAll?.map((x, index) => (
                            <div key={index} className="row">
                              <div className="col-4">
                                <p className="text-muted m-b-5">Total [sheet]</p>
                                <h6>{new Intl.NumberFormat().format(x.sum_bring_sheets)}</h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Domestic [sheet]</p>
                                <h6>{new Intl.NumberFormat().format(Number(x.sum_bring_sheets))}</h6>
                              </div>
                              <div className="col-4">
                                <p className="text-muted m-b-5">Foreign [sheet]</p>
                                <h6>0</h6>
                              </div>

                              <div className="col-4">
                                <p className="text-muted m-b-5">Total price</p>

                                <h6>
                                  {new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' }).format(
                                    x.SUM_Bring_Price,
                                  )}
                                </h6>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* <div id="rent-incomeReSort" style={{ height: "100px" }}></div> */}
                    </div>
                  </div>
                </div>
              )}

              {String(userData.appAccess).toUpperCase().includes('PND') && (
                <>
                  <div id="allPnD1" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '5px' }}>reMail's Widgets </span>

                    <select
                      id="slCompany"
                      name="select"
                      className=""
                      onChange={(e) => handleValueDropDocument(e)}
                      value={selectedCompany}
                      style={{}}
                    >
                      {company?.map((x) => (
                        <option value={x.id}>{x.companyName}</option>
                      ))}
                    </select>
                  </div>
                  <hr id="allPnD2" style={{ marginTop: '1px', backgroundColor: 'black', opacity: 1 }}></hr>
                </>
              )}
              {/* reSort widget*/}
              {String(userData.appAccess).toUpperCase().includes('PND') && <Widget />}
              {String(userData.appAccess).toUpperCase().includes('TNT') && (
                <>
                  <div id="allTnT1" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '5px' }}>reTrack's Widgets </span>

                    <select
                      id="slCompanyTnT"
                      name="select"
                      className=""
                      onChange={(e) => handleValueDropDocumentTnT(e)}
                      value={selectedCompanyTnT}
                      style={{}}
                    >
                      {companyTnT?.map((x) => (
                        <option value={x.companyName}>{x.companyName}</option>
                      ))}
                    </select>
                  </div>
                  <hr id="allTnT2" style={{ marginTop: '1px', backgroundColor: 'black', opacity: 1 }}></hr>
                </>
              )}
              {/* reSort widget*/}
              {String(userData.appAccess).toUpperCase().includes('TNT') && <WidgetTnT idCom={selectedCompanyTnT} reload={timeReload} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Default;
